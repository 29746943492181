export const pmListIFrame = [
  'meshulam',
  'meshulam-bit',
  'meshulam-v2',
  'meshulam-v2-bit',
  'meshulam-google-pay',
  'broomfoundation',
  'asserbishvil',
  'yaadpay',
  'israeltoremet',
  'bancontact',
  'achisomoch',
  'paygate',
  'mhtrust',
  'zoreyatzedokos',
  'yadshlomo',
  'belzvouchers',
  'uta',
  'cmz',
  'threepillars',
  'kolyom',
  'dlocal',
  'forward',
  'nedarim-plus-native',
  'nedarim-plus-native-bit',
  'mercadopago',
  'mercadopago-direct',
  'tranzila',
  'tranzila-bit',
  'walletdoc',
  'walletdoc-direct',
  'authorize',
  'pelecard',
  'cardcom',
  'cardcom-bit',
  'jaffa',
  'jaffa-bit',
  'icredit-rivhit',
  'blink-fidelipay',
  'blink-fidelipay-direct',
  'aminut',
  'kehilot',
  'kehilot-bit',
  'pledger',
  'mancal',
  'mancal-bit',
  'usaepay',
  'stripe-element',
  'stripe-terminal',
  'kcbobov',
  'gama',
  'gama-bit',
  'tevini',
  'peach',
  'peach-bit',
  'powerdoc-direct-debit',
  'asaas',
];

export default { pmListIFrame };
