
import Vue from 'vue';

import cardNumberInput from '../../shared/card-number-input/card-number-input.vue';
import cardExpDate from '../../shared/card-exp-date/card-exp-date.vue';

const VueComponent = Vue.extend({
  components: {
    cardNumberInput,
    cardExpDate,
  },

  inject: ['$validator'],

  data() {
    return {
      cardNo: '',
      expDate: {
        month: '',
        year: '',
      },
      cvv: '',
    }
  },

  computed: {
    gatewayParams(): any {
      return {
        pledger_card_no: this.cardNo.replace(/ /g, ''),
        pledger_exp_date: Object.values(this.expDate).join(''),
        pledger_cvv: this.cvv,
      };
    },
  },

  watch: {
    gatewayParams() {
      this.setParams();
    },
  },

  methods: {
    setParams() {
      this.$store.commit('setDonationIncludedGatewayParams', this.gatewayParams);
    },
  },
});
export default class PLedgerDirect extends VueComponent {}
