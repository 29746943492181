
import customMapState from '@/helpers/mapHelper';
import { IRootState } from '@/models/IRootState';
import Vue from 'vue';

const VueComponent = Vue.extend({
  inject: ['$validator'],

  data() {
    return {
      asaasCpfCnpj: '',
    };
  },

  computed: {
    ...customMapState({
      redirectConfig: (state: IRootState) => state.donation.donationParams
        .data.attributes.config?.redirect_config,
      locale: (state: IRootState) => state.i18n.locale,
    }),
  },

  mounted() {
    this.$root.$on('setDonationGatewayParams', (resolve: (value?: unknown) => void) => {
      const { origin, pathname } = window.location;

      if (this.redirectConfig?.open_popup_on_desktop && !this.$isMobile()) {
        resolve(false);
        return;
      }

      this.$store.commit(
        'setDonationIncludedGatewayParams',
        {
          redirect_url: `${origin}${pathname}?lang=${this.locale}`,
          asaas_cpf_cnpj: this.asaasCpfCnpj,
        },
      );
      resolve(false);
    });
  },
});
export default class YadShlomo extends VueComponent {}
