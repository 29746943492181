
import Vue from 'vue';
import axios, { AxiosResponse } from 'axios';
import { mapActions, mapGetters } from 'vuex';
import customMapState from '@/helpers/mapHelper';
import { IDonationStreams, ITeamToGatewayConfig } from '@/models/IDonationParams';
import { get } from '@/helpers/getUrlParameters';
import urls from '@/constants/urls';
import { useChariot } from '@/compositions/chariot';
import { IRootState } from '../../models/IRootState';
import { IGatewayListByCurrency, Attributes } from '../../models/IGatewayList';
import FbmMeshulamBitAlert from './FbmMeshulamBitAlert.vue';

import { ITeamResponse } from './subcomponents-models/DonateWith.models';

const PAYMENT_ALT_NAMES = {
  'checkout-fi': 'Paytrail',
}

const VueComponent = Vue.extend({
  components: {
    FbmMeshulamBitAlert,
  },

  data() {
    return {
      chosenGatewayId: get('force_gateway_id'),
      lockPreSelectGatewayByTM: false,

      cdnHost: 'https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com',

      userSelectedGwtID: '',
    };
  },

  computed: {
    ...customMapState({
      giftAid: (state: IRootState) => state.donation.donationData.attributes.gift_aid,
      installmentTm: (state: IRootState) => state.donation.donationData.attributes.installment_tm_option,
      installmentPeriod: (state: IRootState) => state.donation.donationData.attributes.installment_period,
      config: (state: IRootState) => state.donation.donationParams.data.attributes.config,
      streamList: (state: IRootState) => state.donation.donationParams.data.attributes.config.donation_streams,
      currencyListLength: (state: IRootState) => state.donation.donationParams.data.attributes.available_currency_list.length,
      countryList: (state: IRootState) => state.donation.donationParams.data.attributes.address_config.country_list,
      teamId: (state: IRootState) => state.donation.donationData.attributes.team_id,
      firstSelectedTeam: (state: IRootState) => state.donation.first_selected_team,
      campaignId: (state: IRootState) => state.donation.donationData.attributes.campaign_id,
      locale: (state: IRootState) => state.i18n.locale,
      forceGatewayId: (state: IRootState) => state.gateways.force_gateway_id,
      lockGateway: (state: IRootState) => state.gateways.lock_gateway,
      installmentConfig: (state: IRootState) => state.donation.donationParams.data.attributes
        .config.installment_config,
    }),

    ...mapGetters({
      getStreamId: 'getStreamId',
      gatewayList: 'getGatewayListByCurrency',
      gatewayListByTeam: 'getGatewayListByTeam',
      useParentTeamGatewayForChildTeam: 'useParentTeamGatewayForChildTeam',
      useGrandParentTeamGatewayForChildTeam: 'useGrandParentTeamGatewayForChildTeam',
      installmentMaxTmOptionMonths: 'installmentMaxTmOptionMonths',
    }),

    teamToGatewayConfig(): ITeamToGatewayConfig | null {
      return this.config.team_to_gateway_config;
    },

    teamToGateway(): ITeamToGatewayConfig['team_gateway'] | null {
      return this.$store.getters.teamToGateway;
    },

    teamFilterGateway(): ITeamToGatewayConfig['team_filter_gateway'] | null {
      return this.$store.getters.teamFilterGateway;
    },

    donationStreamsConfig() {
      return this.config.donation_streams_config
    },

    chosenGateway: {
      get() {
        const { paymentConfig } = this.$store.state.donation;
        if (paymentConfig && Object.keys(paymentConfig).length) {
          return paymentConfig;
        }

        return false;
      },
    },

    stream(): IDonationStreams | undefined {
      return this.streamList.find(
        s => s.id === Number(this.getStreamId),
      );
    },

    gatewayListFiltered(): IGatewayListByCurrency[] {
      const res = this.gatewayList.filter((el: IGatewayListByCurrency) => {
        if (this.stream?.gateway_id) {
          return this.stream.gateway_id === parseInt(el.id, 10);
        }
        return true;
      })

      if (this.stream?.gateway_id && !res?.length) {
        return this.gatewayList;
      }

      return res;
    },

    gatewayListByTeamFiltered(): IGatewayListByCurrency[] {
      const res = this.gatewayListByTeam.filter((el: IGatewayListByCurrency) => {
        if (this.stream?.gateway_id) {
          return this.stream.gateway_id === parseInt(el.id, 10);
        }
        return true;
      })

      if (this.stream?.gateway_id && !res?.length) {
        return this.gatewayListByTeam;
      }

      return res;
    },

    gatewayListUI(): IGatewayListByCurrency[] {
      if (this.gatewayListByTeamFiltered.length) {
        return this.gatewayListByTeamFiltered;
      }

      return this.gatewayListFiltered;
    },

    paymentTypeProps() {
      const props: Record<string, any> = {}

      if (this.teamId) {
        props.teamId = this.teamId
      }

      return props
    },
  },

  watch: {
    gatewayList: {
      immediate: true,
      handler(list: IGatewayListByCurrency[]) {
        const { tm, non_tm } = this.config.gateway_for_tm_config;

        this.$nextTick(() => {
          if (list.length > 0) {
            if (this.chosenGateway && this.giftAid) {
              this.updatePaymentConfig(this.chosenGateway);
            } else if (this.chosenGateway && tm && non_tm) {
              return
            } else if (!this.stream) {
              const pc = this.getPaymentConfig(list)
              this.updatePaymentConfig(pc);
            }
            if (this.teamId) {
              this.preselectGatewayBasedOnTeam();
            }
          } else {
            this.$store.commit('setPaymentConfig', { id: null });
            this.$store.commit('setPaymentMethod', '');
            this.$store.commit('setGiftAid', false);
          }
        });
      },
    },
    gatewayListFiltered: {
      immediate: true,
      handler(list: IGatewayListByCurrency[]) {
        const state = this.$store.state as IRootState;
        const pc = this.getPaymentConfig(list)
        if (!state.donation.paymentMethod && this.stream) {
          this.updatePaymentConfig(pc, false);
        } else {
          this.updatePaymentConfig(pc);
        }
      },
    },
    chosenGateway(value: any) {
      if (value) {
        this.chosenGatewayId = value.id
      }
    },
    teamId(val, old) {
      if (val || (!Number(val) && Number(old) > 0)) {
        this.preselectGatewayBasedOnTeam()
      }
    },
    installmentTm() {
      if (this.lockPreSelectGatewayByTM) {
        this.lockPreSelectGatewayByTM = false;
        return
      }
      this.preSelectGatewayByTM()
    },
    installmentPeriod() {
      this.preSelectGatewayByTM()
    },
  },

  methods: {
    ...mapActions({
      setGatewayAndLock: 'setGatewayAndLock',
    }),

    gatewayLockFilter(teamId: string) {
      if (this.teamFilterGateway && this.teamFilterGateway[teamId]) {
        return this.teamFilterGateway[teamId];
      }

      if (this.teamToGateway && this.teamToGateway[teamId]) {
        return [this.teamToGateway[teamId]];
      }

      return [];
    },

    getImg(item: IGatewayListByCurrency) {
      const { name, icon_url, size } = item.attributes;
      if (icon_url) {
        return {
          src: icon_url,
          class: size ? `payment-plate__img-${size}` : 'payment-plate__img-30',
          alt: name,
        };
      }

      const GATEWAY_IMAGES = {
        'meshulam-bit': {
          src: 'https://www.meshulam.biz/wp-content/uploads/2020/06/Bit-e1593420737601.png',
          class: 'payment-plate__img-30',
        },
        'meshulam-v2-bit': {
          src: 'https://www.meshulam.biz/wp-content/uploads/2020/06/Bit-e1593420737601.png',
          class: 'payment-plate__img-30',
        },
        'tranzila-bit': {
          src: 'https://www.meshulam.biz/wp-content/uploads/2020/06/Bit-e1593420737601.png',
          class: 'payment-plate__img-30',
        },
        'jaffa-bit': {
          src: 'https://www.meshulam.biz/wp-content/uploads/2020/06/Bit-e1593420737601.png',
          class: 'payment-plate__img-30',
        },
        'gama-bit': {
          src: 'https://www.meshulam.biz/wp-content/uploads/2020/06/Bit-e1593420737601.png',
          class: 'payment-plate__img-30',
        },
        'mancal-bit': {
          src: 'https://www.meshulam.biz/wp-content/uploads/2020/06/Bit-e1593420737601.png',
          class: 'payment-plate__img-30',
        },
        'kehilot-bit': {
          src: 'https://www.meshulam.biz/wp-content/uploads/2020/06/Bit-e1593420737601.png',
          class: 'payment-plate__img-30',
        },
        'nedarim-plus-native-bit': {
          src: 'https://www.meshulam.biz/wp-content/uploads/2020/06/Bit-e1593420737601.png',
          class: 'payment-plate__img-30',
        },
        'cardcom-bit': {
          src: 'https://www.meshulam.biz/wp-content/uploads/2020/06/Bit-e1593420737601.png',
          class: 'payment-plate__img-30',
        },
        'peach-bit': {
          src: 'https://www.meshulam.biz/wp-content/uploads/2020/06/Bit-e1593420737601.png',
          class: 'payment-plate__img-30',
        },
        'meshulam-google-pay': {
          src: `${this.cdnHost}/images/281/Google_Pay_Logo_1699262287.png`,
          class: 'payment-plate__img-30',
        },
        'checkout-fi': {
          src: `${this.cdnHost}/public_upload/1633978140_8f30fd61588aea0ab2a016859da18522_1633978140.jpg`,
          class: 'payment-plate__img-30',
        },
        chariot: {
          src: `${this.cdnHost}/public_upload/1697219981_3039653539373735353734666437646565616138363431633237313732643465_31363937323139393831.svg`,
          class: 'payment-plate__img-30',
        },
        achisomoch: {
          src: `${this.cdnHost}/public_upload/1708023150_6431333764373962656534623831383666616564336630383931616431636637_31373038303233313530.png`,
          class: 'payment-plate__img-60',
        },
        broomfoundation: {
          src: `${this.cdnHost}/charidy_images/gateways/broom.jpg`,
          class: 'payment-plate__img-80',
        },
        'blink-fidelipay-direct': {
          src: 'https://blink3sixty.co.uk/assets/images/blink360-logo.svg',
          class: 'payment-plate__img-70',
        },
        'blink-fidelipay': {
          src: 'https://blink3sixty.co.uk/assets/images/blink360-logo.svg',
          class: 'payment-plate__img-70',
        },
        asserbishvil: {
          src: `${this.cdnHost}/charidy_images/gateways/asseb.png`,
          class: 'payment-plate__img-90',
        },
        kolyom: {
          src: `${this.cdnHost}/charidy_images/1618239698909704069.jpg`,
          class: 'payment-plate__img-60',
        },
        kcbobov: {
          src: 'https://online.kcbobov.co.uk/assets/images/logo.png',
          class: 'payment-plate__img-60',
        },
        tevini: {
          src: 'https://www.tevini.co.uk/assets/image/logo/logo.png',
          class: 'payment-plate__img-80',
        },
        mhtrust: {
          src: `${this.cdnHost}/public_upload/1654181780_8aa03b8a611b307207537b5b703dadea_1654181780.png`,
          class: 'payment-plate__img-50',
        },
        threepillars: {
          src: `${this.cdnHost}/public_upload/1625604113_5adf8a08f84b634255601c00731b1ff6_1625604113.png`,
          class: 'payment-plate__img-60',
        },
        zoreyatzedokos: {
          src: `${this.cdnHost}/public_upload/1625604140_335bf636f6a7b54521e0a8fb73a41d80_1625604140.png`,
          class: 'payment-plate__img-60',
        },
        yadshlomo: {
          src: 'https://yadshlomo.org/assets/images/logo.png',
          class: 'payment-plate__img-60',
        },
        belzvouchers: {
          src: 'https://belzvouchers.com/assets/images/logo.png',
          class: 'payment-plate__img-60',
        },
        uta: {
          src: `${this.cdnHost}/public_upload/1708023171_3761373764663633633838326462383338363065363231333932356236323265_31373038303233313731.png`,
          class: 'payment-plate__img-60',
        },
        cmz: {
          src: 'https://online.cmz.org.uk/assets/images/logo.png',
          class: 'payment-plate__img-60',
        },
        asaas: {
          src: 'https://files.readme.io/402079d-shape.svg',
          class: 'payment-plate__img-70',
        },
        pledger: {
          src: `${this.cdnHost}/public_upload/1736376580_3936643666326537653166373035616235653539633834613664633030396232_31373336333736353830.svg`,
          class: 'payment-plate__img-70',
        },
        'pledger-direct': {
          src: `${this.cdnHost}/public_upload/1736376580_3936643666326537653166373035616235653539633834613664633030396232_31373336333736353830.svg`,
          class: 'payment-plate__img-70',
        },
        donorsfund: {
          src: `${this.cdnHost}/public_upload/1736376820_3163633062396237363464313661616162336234323131646365363164313731_31373336333736383230.svg`,
          class: 'payment-plate__img-70',
        },
        payarc: {
          src: `${this.cdnHost}/public_upload/1736378147_6633336330623263336566346165316661363330383435353666373031316430_31373336333738313437.svg`,
          class: 'payment-plate__img-70',
        },
      };

      for (const [name, config] of Object.entries(GATEWAY_IMAGES)) {
        if (name === item.attributes.name) {
          return {
            ...config,
            alt: name,
          };
        }
      }

      const PAYMENT_LABEL_PATTERNS = {
        CASH_APP: {
          pattern: /Cash\s*App/i,
          image: {
            src: `${this.cdnHost}/public_upload/1736377150_6633653330366538333633353639616161356133316564656238346539346166_31373336333737313530.svg`,
            class: 'payment-plate__img-30',
          },
        },
      };

      const itemLabel = this.getlabel(item);
      for (const [, config] of Object.entries(PAYMENT_LABEL_PATTERNS)) {
        if (config.pattern.test(itemLabel)) {
          return {
            ...config.image,
            alt: name,
          };
        }
      }

      return null
    },

    getAltLabel(gatewayName: string): string {
      const entries = Object.entries(PAYMENT_ALT_NAMES);
      const map = new Map(entries);
      const found = map.get(gatewayName);

      if (!found) {
        return gatewayName;
      }

      return found;
    },

    getlabel(item : IGatewayListByCurrency): string {
      const tkname = `${item.attributes.name}_label`
      const tk = this.$t(`donation.${tkname}`)

      const { value, langs } = this.config.custom_translation_config;
      const customTranslationDonation = langs?.[this.locale]?.donation as {[key:string]: string} | undefined
      if (value && customTranslationDonation?.[tkname]) {
        // pri1: custom translation
        return tk || '';
      }

      if (item.attributes.custom_label) {
        // pri2: custom label
        return item.attributes.custom_label
      }

      if (tk !== `donation.${tkname}`) {
        // pri3: default translation
        return tk || '';
      }

      // pri4: native gateway label
      return item.attributes.label || this.getAltLabel(item.attributes.name)
    },

    selectGateway(gatewayID?: string | string[]) {
      if (gatewayID && Array.isArray(gatewayID) && gatewayID.length === 0) {
        return this.setGatewayAndLock(0)
      }

      const gateway = this.gatewayListFiltered.filter(item => gatewayID!.includes(item.id));
      if (!gateway) {
        return this.setGatewayAndLock(0)
      }

      if (this.teamToGatewayConfig?.lock_gateway) {
        this.setGatewayAndLock(gatewayID)
      } else {
        this.updatePaymentConfig(gateway[0])
      }

      return null;
    },

    async preselectGatewayBasedOnTeam() {
      const id = this.teamId;
      const currentTeamGateway = this.gatewayLockFilter(String(id));

      const gatewayID = (
        (
          this.useParentTeamGatewayForChildTeam
          || this.useGrandParentTeamGatewayForChildTeam
        )
        && !currentTeamGateway
      )
        ? this.firstSelectedTeam?.parent_team_id && this.gatewayLockFilter(this.firstSelectedTeam.parent_team_id)
        : currentTeamGateway;

      if (
        this.useGrandParentTeamGatewayForChildTeam
        && this.firstSelectedTeam?.parent_level === 0
        && this.firstSelectedTeam?.parent_team_id
      ) {
        const { data } = await this.fetchTeam({ team_id: this.firstSelectedTeam?.parent_team_id })

        const [team] = data.data;
        const gptid = team?.attributes.parent_team_id;

        if (!gptid) {
          return this.setGatewayAndLock(0)
        }

        const gatewayID = this.gatewayLockFilter(String(gptid));

        return this.selectGateway(gatewayID)
      }

      return this.selectGateway(gatewayID)
    },

    fetchTeam(params: any): Promise<AxiosResponse<ITeamResponse>> {
      return axios
        .get<ITeamResponse>(
          urls.getTeams
            .replace(':campaignId', this.campaignId),
          { params },
        )
    },

    getPaymentConfig(list: IGatewayListByCurrency[]) {
      const fgi = get('force_gateway_id') || this.userSelectedGwtID
      const pc = list.find(el => el.id === fgi) || list[0];

      if (
        this.installmentPeriod > 1
        && this.installmentConfig.available_tm_option
        && this.installmentPeriod <= this.installmentMaxTmOptionMonths
      ) {
        const { tm, non_tm } = this.config.gateway_for_tm_config;

        if (tm || non_tm) {
          const val = this.installmentTm;

          if (val && tm) {
            return list.find(el => el.id === String(tm)) || pc
          }

          if (!val && non_tm) {
            return list.find(el => el.id === String(non_tm)) || pc
          }
        }
      }

      return pc;
    },

    getIcon({ name, label }: Attributes): string {
      if (label.includes('Credit Card')) return 'fas fa-credit-card';

      switch (name) {
        case 'paypal':
        case 'stripe':
          return `fab fa-${name}`;

        case 'pledgeonly':
          return 'far fa-handshake';

        case 'fund':
          return 'fa fa-university';

        default:
          return 'far fas fa-credit-card';
      }
    },

    onUpdatePaymentConfig(config: IGatewayListByCurrency) {
      this.lockPreSelectGatewayByTM = true

      this.userSelectedGwtID = config?.id

      this.updatePaymentConfig(config);
    },

    updatePaymentConfig(config: IGatewayListByCurrency, updStream: boolean = true) {
      if (
        this.stream?.gateway_id !== Number(config?.id)
          && this.donationStreamsConfig?.relation === 'gateway_stream'
      ) {
        const stream = this.streamList.find(
          s => s.gateway_id === Number(config?.id),
        );
        if (stream && updStream) {
          this.$store.commit('setStream', { ...stream });
        }
      }

      if (config.attributes.name === 'chariot') {
        useChariot().setup();
      }

      this.chosenGatewayId = config?.id;
      this.$store.commit('setPaymentConfig', config);
      this.$store.commit('setPaymentMethod', config?.attributes.name);
    },

    getDescription(item: IGatewayListByCurrency): string {
      const { country: countruOlc } = item.attributes.organization_legal_entity || {};
      const { description, description_key } = item.attributes;
      const countryObj = this.countryList.find(
        el => (el.iso_code || '').toLowerCase() === (countruOlc || '').toLowerCase(),
      );
      const country = (countryObj && countryObj.name) || countruOlc;

      if (!description) {
        return this.$t(description_key, { country }) || '';
      }

      return description;
    },

    preSelectGatewayByTM() {
      const val = this.installmentTm;
      const { tm, non_tm } = this.config.gateway_for_tm_config;

      if (val && tm) {
        const gateway = this.gatewayListFiltered.find(el => el.id === String(tm))

        if (gateway) {
          this.updatePaymentConfig(gateway);
        }
      }

      if (!val && non_tm) {
        const gateway = this.gatewayListFiltered.find(el => el.id === String(non_tm))

        if (gateway) {
          this.updatePaymentConfig(gateway);
        }
      }
    },
  },
});
export default class PaymentType extends VueComponent {}
