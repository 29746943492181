// eslint-disable-next-line import/no-extraneous-dependencies
import qs from 'qs';
import Vue from 'vue';
import axios, { AxiosRequestConfig } from 'axios';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { nanoid } from 'nanoid';
import { IRequestPaymentMethodPayload } from 'braintree-web-drop-in';
import urls from '@/constants/urls';
import { countryList } from '@/constants/countryList';
import features from '@/constants/features';
import Donation, {
  IDonationRequest, PaymentConfig, IDonationIncluded, IIncluded, IError, IDonationIncludedAttributesExtended, RelationshipsData,
} from '@/store/modules/models/donation';
import { RootState } from '@/store/modules/translation';
import {
  IDonationParams, RecurringConfig, ITeamToGatewayConfig,
} from '@/models/IDonationParams';
import { ICampaign } from '@/models/ICampaign';
import allowedList from '@/constants/implementedPaymentMethods';
import { ICampaignParams } from '@/models/ICampaignParams';
import { popupWindow } from '@/helpers/newWindow';
import { composeReferrer } from '@/helpers/getUtmParameters';
import distinct from '@/helpers/arrayHelper';
import getCookie from '@/helpers/cookie';
import { deepCopy } from '@/helpers/objectHelper';
import {
  get, getCampaignAuctionPoints, getMatchedDonationId, getTeamId, searchParams,
} from '@/helpers/getUrlParameters';
import { reMapConfig } from '@/helpers/reMapData';
import { createError, parseError } from '@/helpers/customError';
import { getPlatform, isFBApp, isChrome } from '@/helpers/platform';
import { setDonationDataToLoacalStarage } from '@/helpers/localStorage';
import { stateList } from '@/constants/stateList';
import { isBlocked, addGALinker } from '@/helpers/analytics';
import { IXchangeResponse } from '@/components/subcomponents/subcomponents-models/DonationAmount-models';
import { getOverwriteReturnToWithVars } from '@/helpers/overwriteReturnTo';
import { useExternalScript } from '@/helpers/useExternalScript';
import { logger } from '@/helpers/appLogger';
import { useReCaptchaV3 } from '@/compositions/captcha';
import { removeBeforeUnloadListener } from '@/before-unload';
import { usePaypalV2 } from '@/compositions/paypalv2';
import { injectAnalaytics } from '@/helpers/googleTagManager';
import replaceVarsInGatewayFields from '../../helpers/replaceVarsInGatewayFields';

const referrerId = getCookie('referrer_id');

const { getToken } = useReCaptchaV3()

const state: Donation = {
  readonly: Boolean(get('readonly')),
  teamReadonly: Boolean(get('team_readonly')) && Boolean(getTeamId()),
  // Overlay
  overlay: {
    show: false,
    redirectLink: '',
  },
  // 0 - pre form steps, 1 - initial form, 2 - confirmation, 3 - thank you
  stepFlag: 0,
  // total teams amount
  teamsTotal: 0,
  // donation total amount
  totalAmount: 0,
  originAmount: 0,
  changeRate: 0,
  campaign: {} as ICampaign,
  donationParams: {} as IDonationParams,
  included: [],
  paymentMethod: '',
  paymentConfig: {} as PaymentConfig,
  gatewayConfig: {},
  zipAddress: '',
  translation: {},
  authorizationToken: '',
  donationData: {
    attributes: {
      // Amount module
      amount: 0,
      campaign_id: '',
      payment_option_id: null,
      currency: '',
      currency_sign: '',
      include_payment_system_fee: false,
      include_payment_system_fee_amount: 0,
      recurring: false,
      recurring_period: 0,
      recurring_interval: get('recurring_interval') || 'month',
      installment: false,
      installment_period: 1,
      installment_interval: 'month',
      installment_tm_option: false,

      test: searchParams.get('test') !== null ? true : undefined,

      // Personal data module
      billing_first_name: get('firstname'),
      billing_last_name: get('lastname'),
      billing_name: get('fullname'),
      display_name: get('displayname'),
      receipt_name: '',
      email: get('email'),
      message: '',

      // Donate with module
      team_id: 0,
      donate_to_all_teams: false,
      stream_id: Number(get('stream_id')),
      stream_title: '',
      get_data: get('meta_data_json[get_data]'),

      // Address module
      address_country: get('countrycode').toLocaleUpperCase(),
      address_state: get('state'),
      address_city: get('city'),
      address_zip: get('zip'),
      address_line1: get('address'),
      address_number: get('address_number'),
      address_line2: '',
      phone: get('phone'),
      mail_receipt: false,
      sms_receipt: false,

      // Create Account
      save_stripe_card: false,

      tip_amount: 0,

      gift_aid: false,

      referrer: composeReferrer(searchParams, referrerId),
      captcha_token: '',
      lang: '',
      hide_donation_amount: false,

      platform: getPlatform(),
      referrer_shortlink: get('referrer_shortlink'),

      zero_recurring: false,

      nedarimg_bit_url: '',

      israeli_zehut: '',

      pledge_ref: Number(get('pledgeref')),
    },
    relationships: {
      // TODO: mock-data -> should clarify
      gateway_params: {
        data: {
          type: 'gateway_params',
          id: '0',
        },
      },
    },
  },
  // TODO: mock-data -> should clarify
  donationIncludedGatewayParams: {
    type: 'gateway_params',
    id: '0',
    attributes: {} as IDonationIncludedAttributesExtended,
  },
  donationIncluded: [],
  loadTranslation: false,
  isLoadCampaignParams: false,
  campaignParamsLoading: false,
  //  Dialog props
  dialog: {
    visible: false,
    title: '',
    iframe: {
      src: '',
    },
  },
  error: null,
  isSubmitted: false,
  retrievedFromZip: false,
  stripePaymentMethods: [],
  donationId: 0,
  savedCreditCard: null,
  donorAccount: null,
  iframe: Boolean(get('iframe')),
  first_selected_team: undefined,
  salutations: '',
  donateAnonymouslyFlag: false,
};

const mutations: MutationTree<Donation> = {
  setCampaign(state, data) {
    state.campaign = data;
  },

  setCampaignId(state, id: string) {
    state.donationData.attributes.campaign_id = id;
  },

  setDonationParams(state, data) {
    state.donationParams = reMapConfig(data);
  },

  setIncluded(state, data) {
    state.included = data;
  },

  setLoadingCampaign(state, value) {
    state.isLoadCampaignParams = value;
  },

  setCampaignParamsLoading(state, value) {
    state.campaignParamsLoading = value;
  },

  setLoadingTranslation(state, value) {
    state.loadTranslation = value;
  },

  setPaymentMethod(state, value) {
    state.paymentMethod = value;
  },

  setTranslation(state, data) {
    state.translation = data;
  },

  setZipAddress(state, data) {
    state.zipAddress = data;
  },

  setDonationAmount(state, data) {
    if (!data) {
      state.donationData.attributes.amount = data;
    } else {
      state.donationData.attributes.amount = state.donationParams.data.attributes.ui_mode
        === features.DELTA_DONATION_AND_TIP
        ? Number(data)
        : Number(data * 100);
    }
  },

  setTotalAmount(state, data) {
    state.totalAmount = data;
  },

  setOriginAmount(state, data) {
    state.originAmount = Number(data) || 0;
  },

  setChangeRate(state, data: number) {
    state.changeRate = data;
  },

  setReadonly(state, data: boolean) {
    state.readonly = data;
  },

  setIncludePaymentFeeStatus(state, data) {
    state.donationData.attributes.include_payment_system_fee = data;
  },

  setIncludePaymentFeeAmount(state, data) {
    state.donationData.attributes.include_payment_system_fee_amount = data;
  },

  setDonateToAllTeams(state, data) {
    state.donationData.attributes.donate_to_all_teams = data;
  },

  setRecurringPeriod(state, data) {
    state.donationData.attributes.recurring_period = Number(data);

    const { period } = state.donationParams.data.attributes.config.recurring_config
    state.donationData.attributes.recurring_interval = period || 'month';

    switch (period) {
      case 'month':
        state.donationData.attributes.recurring = Number(data) > 1
        break;

      case 'year':
        state.donationData.attributes.recurring = Number(data) > 0
        break;

      default:
        state.donationData.attributes.recurring = Number(data) > 1
        break;
    }
  },

  setConfigRecurringDuration(state, data) {
    state.donationParams.data.attributes.config.recurring_config.duration = Number(data);
  },

  setInstallmentStatus(state, data) {
    state.donationData.attributes.installment = data;
  },

  setInstallmentPeriod(state, data) {
    state.donationData.attributes.installment_period = Number(data);
  },

  setInstallmentTmOption(state, data) {
    state.donationData.attributes.installment_tm_option = data;
  },

  setPaymentConfig(state, data) {
    state.paymentConfig = data;
    state.donationData.attributes.payment_option_id = Number(data?.id);

    if (
      state.paymentConfig.attributes.name !== 'stripe'
      && state.error?.key === 'stripe-script-load-error'
    ) {
      state.error = null;
    }
  },

  setDonationRelationshipsCustomData(state, data) {
    state.donationData.relationships.custom_data = {
      data,
    };
  },

  setDonationRelationshipsMultipleTeams(state, list: RelationshipsData[]) {
    const data = distinct(list);
    state.donationData.relationships.donate_to_multiple_teams = { data };
  },

  setDonationRelationshipsGatewayParams(state, data) {
    state.donationData.relationships.gateway_params = {
      ...data,
    };
  },

  setDonationIncludedMultipleTeams(state, data: IDonationIncluded[]) {
    const list = state.donationIncluded.filter(el => el.type !== 'donate_to_multiple_teams')
    const filteredList = data.filter(el => (el.attributes.amount || 0) > 0);

    state.donationIncluded = list;
    state.donationIncluded = state.donationIncluded.concat(filteredList);
  },

  setDonationIncluded(state, data) {
    const list = state.donationIncluded.filter(el => el.type !== 'custom_data');
    state.donationIncluded = list;
    state.donationIncluded = state.donationIncluded.concat(data);
  },

  setDonationCurrency(state, data) {
    state.donationData.attributes.currency = data.code.toLowerCase();
    state.donationData.attributes.currency_sign = data.sign;
  },

  setDonationIncludedGatewayParams(state, data) {
    state.donationIncludedGatewayParams.attributes = {
      ...data,
    };
  },

  // Donate With module setters
  setTeam(state, data) {
    state.first_selected_team = data;
    state.donationData.attributes.team_id = data.id;
  },

  setStream(state, data) {
    state.donationData.attributes.stream_id = Number(data.id);
    state.donationData.attributes.stream_title = data.title;
  },
  // --end Donate with module setters

  //  Personal data module setters
  setFirstName(state, data: string) {
    state.donationData.attributes.billing_first_name = data;
  },

  setLastName(state, data: string) {
    state.donationData.attributes.billing_last_name = data;
  },

  setBillingName(state, data: string) {
    state.donationData.attributes.billing_name = data;
  },

  setDisplayName(state, data: string) {
    state.donationData.attributes.display_name = data;
  },

  setSalutations(state, data: string) {
    state.salutations = data;
  },

  setReceiptName(state, data: string) {
    state.donationData.attributes.receipt_name = data;
  },

  setEmail(state, data: string) {
    state.donationData.attributes.email = data;
  },

  setOrganizationEmail(state, data: string) {
    state.donationData.attributes.organization_email = data;
  },

  setMessage(state, data: string) {
    state.donationData.attributes.message = data;
  },
  // -- end Personal data module setters

  //  Address data module setters
  setCountry(state, data) {
    state.donationData.attributes.address_country = data;
  },

  setState(state, data) {
    state.donationData.attributes.address_state = data;
  },

  setCity(state, data) {
    state.donationData.attributes.address_city = data;
  },

  setZipcode(state, data) {
    state.donationData.attributes.address_zip = data;
  },

  setAddress(state, data) {
    state.donationData.attributes.address_line1 = data;
  },

  setAddressNumber(state, data) {
    state.donationData.attributes.address_number = data;
  },

  setSuite(state, data) {
    state.donationData.attributes.address_line2 = data;
  },

  setPhone(state, data) {
    state.donationData.attributes.phone = data;
  },

  setMailReceipt(state, data) {
    state.donationData.attributes.mail_receipt = data;
  },

  setLocked(state, data) {
    state.donationData.attributes.locked = data;
  },

  setLockedType(state, data) {
    state.donationData.attributes.locked_type = data;
  },

  setLockedVarInt(state, data) {
    state.donationData.attributes.locked_var_int = data;
  },

  deleteLockedVarInt(state) {
    delete state.donationData.attributes.locked_var_int;
  },

  setSmsReceipt(state, data) {
    state.donationData.attributes.sms_receipt = data;
  },

  setTip(state, data) {
    /* Tip should be an integer */
    state.donationData.attributes.tip_amount = Math.ceil(data);
  },

  setCaptchaToken(state, data) {
    state.donationData.attributes.captcha_token = data;
  },

  setTest(state) {
    state.donationData.attributes.test = true;
  },

  setHideAmount(state, value) {
    state.donationData.attributes.hide_donation_amount = value;
  },
  // -- end Personal data module setters

  //  Dialog data module setters
  setDialogVisibleStatus(state, data) {
    state.dialog.visible = data;
  },
  setDialogTitle(state, data: string) {
    state.dialog.title = data;
  },
  setDialogIframeSrc(state, data: string) {
    state.dialog.iframe.src = data;
  },
  // -- end Dialog data module setters
  setStepFlag(state, data: number) {
    state.isSubmitted = false;
    state.stepFlag = data;

    if (state.stepFlag === 3) {
      state.error = null
    }
  },
  // set total teams amount from response (header x-total-teams)
  setTeamsTotalAmount(state, data) {
    state.teamsTotal = data;
  },
  setError(state, data: IError | null) {
    if (!state.error?.skipNext) {
      state.error = data;
    } else {
      state.error.skipNext = false;
    }
  },
  setIsSubmitted(state, data: boolean) {
    state.isSubmitted = data;
  },
  setDefaultDonationData(state) {
    state.donationData.attributes.billing_first_name = '';
    state.donationData.attributes.billing_last_name = '';
    state.donationData.attributes.billing_name = '';
    state.donationData.attributes.display_name = '';
    state.donationData.attributes.receipt_name = '';
    state.donationData.attributes.email = '';
    state.donationData.attributes.message = '';

    // Address module
    // state.donationData.attributes.address_country = '';
    state.donationData.attributes.address_state = '';
    state.donationData.attributes.address_city = '';
    state.donationData.attributes.address_zip = '';
    state.donationData.attributes.address_line1 = undefined;
    state.donationData.attributes.address_number = '';
    state.donationData.attributes.address_line2 = '';
    state.donationData.attributes.phone = '';

    state.donationData.attributes.referrer = '';
    state.donationData.attributes.captcha_token = '';

    state.donationData.attributes.hide_donation_amount = false;
  },
  setOverlay(state, data) {
    state.overlay.show = data;
  },
  setOverlayRedirectLink(state, url) {
    state.overlay.redirectLink = url;
  },
  setRetrievedFromZip(state, data) {
    state.retrievedFromZip = data;
  },
  setGiftAid(state, data) {
    state.donationData.attributes.gift_aid = data;
  },
  setZeroRecurring(state, data) {
    state.donationData.attributes.zero_recurring = data;
  },
  // Create Account
  setSaveStripeCard(state, data) {
    state.donationData.attributes.save_stripe_card = data;
  },
  setAuthorizationToken(state, data) {
    state.authorizationToken = data;
  },
  setStripePaymentMethods(state, data) {
    state.stripePaymentMethods = data;
  },
  setDonationId(state, data) {
    state.donationId = data;
  },
  setSavedCreditCard(state, data) {
    state.savedCreditCard = data;
  },
  setDonorAccount(state, data) {
    state.donorAccount = data;
  },
  setGetData(state, data) {
    state.donationData.attributes.get_data = data;
  },
  setNedarimBitURL(state, data) {
    state.donationData.attributes.nedarimg_bit_url = data;
  },
  setIsraeliZehut(state, data) {
    state.donationData.attributes.israeli_zehut = data;
  },
  setDonorData(state, data) {
    const firstName = data?.attributes?.first_name || '';
    const lastName = data?.attributes?.last_name || '';

    const fullName = `${firstName} ${lastName}`.trim()

    const displayName = data?.attributes?.display_name || fullName;

    const { split_donor_billing_name } = state.donationParams.data.attributes.config
    if (split_donor_billing_name) {
      state.donationData.attributes.billing_first_name = firstName;
      state.donationData.attributes.billing_last_name = lastName;
    } else {
      state.donationData.attributes.billing_name = fullName;
    }

    state.donationData.attributes.display_name = displayName
    state.donationData.attributes.phone = data?.attributes?.phone || '';
    state.donationData.attributes.email = data?.attributes?.email || '';
  },
  setDonateAnonymouslyFlag(state, data) {
    state.donateAnonymouslyFlag = data;
  },
};

const actions: ActionTree<Donation, RootState> = {
  setIsSubmitted({ commit }, value) {
    commit('setIsSubmitted', value)
  },

  setTokenIfValid({ commit, dispatch }, token) {
    axios.get(urls.signIn, { headers: { authorization: `Bearer ${token}` } }).then(() => {
      commit('setAuthorizationToken', token);
      dispatch('getStripePaymentMethods', token);
      dispatch('getDonorAccount', token);
    }).catch(() => {
      commit('setAuthorizationToken', '');
      commit('setStripePaymentMethods', []);
    })
  },

  getStripePaymentMethods({ commit }, jwtToken) {
    axios.get(urls.stripePaymentMethods, {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then(({ data }) => {
        commit('setStripePaymentMethods', data.data);
      })
      // eslint-disable-next-line no-console
      .catch(console.log)
  },

  getDonorAccount({ state, commit }, jwtToken) {
    axios.get(urls.getDonorAccount, {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    }).then(({ data }) => {
      commit('setDonorAccount', data.data);
      if (getCookie('admin_token') === '' && !state.donationParams.data.attributes.config.prevent_donor_data_autofill) {
        commit('setDonorData', data.data);
      }
    })
  },

  getCampaign({ commit }, { cid }) {
    const campaignShortlink = cid;

    const params = {
      extend: ['givingday_stats'],
    }
    return axios
      .get<ICampaign>(
        `${urls.campaignByShortlink.replace(':shortlink', campaignShortlink)}`,
        { params },
      )
      .then(response => {
        commit('setCampaign', response.data);
        commit('setCampaignId', response.data.data.id);
      })
      .catch(e => {
        commit('setError', {
          title: e.response?.statusText || e.message,
          text: e.response?.data?.error,
        });
      });
  },

  getNewCampaign({ commit, dispatch }) {
    axios
      .get(`${urls.getNewOrg}`)
      .then(response => {
        const res = response.data;
        const attr = res.data.attributes;

        res.data.attributes.address_config.country_list = countryList;

        commit('setCampaign', response.data);
        commit('setCampaignId', 0);
        commit('setStream', { id: 0, title: 'test' });
        if (!state.donationData.attributes.address_country) {
          if (attr.ip_location_country_code.length) {
            commit('setCountry', attr.ip_location_country_code);
          }

          if (attr.force_default_currency) {
            const currency = attr.available_currency_list
              .find((el: any) => el.code.toLowerCase() === attr.force_default_currency);

            commit('setDonationCurrency', currency);
          } else {
            const locDefaultCurrencyCode = attr.ip_location_default_currency?.code?.toLowerCase()
            const currency = attr.available_currency_list
              .find((el: any) => el.code.toLowerCase() === locDefaultCurrencyCode);

            if (currency) {
              commit('setDonationCurrency', attr.ip_location_default_currency);
            } else {
              const donationCurrency = attr.available_currency_list[0];
              commit('setDonationCurrency', donationCurrency);
            }
          }
        }

        if (
          attr?.force_default_language
          && !(get('lang') || getCookie('language'))
        ) {
          commit('SET_LOCATE', attr?.force_default_language);
        }

        if (!res.included) throw Error();

        commit('setDonationParams', res);
        commit('setIncluded', res.included || []);

        // commit('setLoadingCampaign', true);
        dispatch('getTranslation')
        // dispatch('getTotalTeamsAmount');
      })
      .catch(() => {
        commit('setError', {
          title: 'Campaign response error',
          text: 'Campaign is not configured correctly, please reach out to our support',
        });
      });
  },

  getCampaignParams({ state, commit, dispatch }) {
    commit('setCampaignParamsLoading', true);

    const url = urls.campaignParams.replace(':campaignId', String(state.campaign.data.id));

    axios
      .get<ICampaignParams>(url)
      .then(response => {
        const res = response.data;
        const attr = res.data.attributes;

        res.data.attributes.address_config.country_list = countryList;

        if (get('ui_mode') && res.data.attributes.ui_mode !== get('ui_mode')) {
          res.data.attributes.ui_mode = get('ui_mode');
        }

        const available_periods_overide = get('available_periods')

        if (available_periods_overide) {
          res.data.attributes.config.recurring_config.available_periods = available_periods_overide?.split('|');
        }

        if (!state.donationData.attributes.address_country) {
          commit('setCountry', attr.ip_location_country_code);
        }

        if (attr.force_default_currency) {
          const currency = attr.available_currency_list
            .find(el => el.code.toLowerCase() === attr.force_default_currency);

          commit('setDonationCurrency', currency);
        } else {
          const locDefaultCurrencyCode = attr.ip_location_default_currency?.code?.toLowerCase()
          const currency = attr.available_currency_list
            .find((el: any) => el.code.toLowerCase() === locDefaultCurrencyCode);

          if (currency) {
            commit('setDonationCurrency', attr.ip_location_default_currency);
          } else {
            const donationCurrency = attr.available_currency_list[0];
            commit('setDonationCurrency', donationCurrency);
          }
        }

        if (
          attr?.force_default_language
          && !(get('lang') || getCookie('language'))
        ) {
          commit('SET_LOCATE', attr?.force_default_language);
        }

        commit('setDonationParams', res);
        dispatch('getTranslation')

        if (!res.included) throw Error();

        commit('setIncluded', res.included || []);

        Promise.all([
          injectAnalaytics({
            tracking_google_analytics_id: res?.data?.attributes?.config?.tracking_google_analytics_id || null,
            tracking_org_google_analytics_id: res?.data?.attributes?.config?.tracking_org_google_analytics_id || null,
            tracking_org_gtm_container: res?.data?.attributes?.config?.tracking_org_gtm_container || null,
          }),
          dispatch('handleStripeRedirect'),
          dispatch('handleRedirect'),
          dispatch('checkIfCanMakePaymentOnPageLoad'),
        ]).then(() => {
          commit('setLoadingCampaign', true);
          commit('setCampaignParamsLoading', false);
        });
      })
      .catch(e => {
        let translationKey = [];

        if (e?.response?.status === 400) {
          translationKey = [
            'donation.internal_error',
            e?.response?.data?.error,
          ];
        } else {
          translationKey = [
            'donation.error_does_not_payment_options_added',
            'Campaign has no payment methods, please contact Charidy support to finish campaign setup',
          ];
        }

        commit('setError', {
          title: 'Error',
          translationKey,
          hideLoader: true,
          hideCloseBtn: true,
        });
      });
  },

  getStateCity({ commit }, params) {
    axios
      .get(
        urls.getStateCity.replace(':isoCode', params.isoCode).replace(':zipCode', params.zipCode),
      )
      .then(({ data }) => {
        if (data && data.data && data.data.attributes) {
          const { state, city } = data.data.attributes;
          if (state) {
            commit('setState', state);
          }
          if (city) {
            commit('setCity', city);
          }
          if (state || city) {
            commit('setRetrievedFromZip', true);
          } else {
            commit('setRetrievedFromZip', false);
          }
        }
      });
  },

  makeDonation({
    state, getters, commit, dispatch, rootState,
  }, { stripeIntentConfirmation = false } = {}) {
    commit('setIsSubmitted', true);

    const campaignId = String(state.campaign.data.id);
    const psConfig = state.paymentConfig.attributes.configuration;
    const { recurring_config, allow_conditional_donations } = state.donationParams.data.attributes.config;

    const getReqData = () => {
      const data = deepCopy(state.donationData);

      let $reqData: IDonationRequest = {
        data,
        included: [...[state.donationIncludedGatewayParams], ...state.donationIncluded],
      };
      $reqData.data.attributes.lang = rootState.translation.locate;

      if ($reqData.data.attributes.recurring && recurring_config.period === 'day') {
        $reqData.data.attributes.amount *= 30;
      }

      if (state.donationParams.data.attributes.ui_mode === features.DELTA_DONATION_AND_TIP) {
        $reqData.data.attributes.amount *= 100;
        $reqData.data.attributes.tip_amount *= 100;
      }

      $reqData.data.attributes.ga = isBlocked()

      dispatch('extendRequestPayloadWithLevels', $reqData).then(res => {
        $reqData = res
      })

      dispatch('extendCustomDataInPayload', $reqData).then(res => {
        $reqData = res
      })

      if (state.salutations) {
        $reqData.data.attributes.display_name = `${state.salutations} ${data.attributes.display_name}`
      }

      $reqData.data.attributes.locked = data.attributes.locked;
      $reqData.data.attributes.locked_type = data.attributes.locked_type;
      $reqData.data.attributes.locked_var_int = data.attributes.locked_var_int;

      const matchedDonationId = getMatchedDonationId();
      if (matchedDonationId && allow_conditional_donations.value && allow_conditional_donations.types_supported.includes('peer_match')) {
        $reqData.data.attributes.matched_donation_id = matchedDonationId;
      }

      const campaignAuctionPoints = getCampaignAuctionPoints();
      if (campaignAuctionPoints > 0) {
        $reqData.data.attributes.campaign_auction_points = campaignAuctionPoints;
      }

      return $reqData;
    }

    const reqData: IDonationRequest = getReqData()
    const config: AxiosRequestConfig = {};

    config.headers = {}

    if (state.authorizationToken) {
      config.headers.authorization = `Bearer ${state.authorizationToken}`
    }

    const donaionInterceptor = axios.interceptors.response.use(response => {
      if (response.config.method === 'post' && !stripeIntentConfirmation) {
        const { attributes } = response.data?.data || {};

        if (attributes?.donation_id) {
          commit('setDonationId', attributes.donation_id);

          axios.interceptors.response.eject(donaionInterceptor);
        }
      }

      return response;
    });

    setDonationDataToLoacalStarage({ data: { attributes: {} } } as IDonationRequest)

    const payPalV2 = usePaypalV2()

    switch (state.paymentMethod) {
      /*
        * Stripe Donation Request doc:
        * https://charidy.postman.co/collections/
        * 1118680-4dcefeaa-697d-9a31-deff-a902483e21ec
        * ?workspace=697f73d0-d3eb-426b-8bcf-94edd1ae7d75#7830249f-b9b9-43a8-9f74-315026d73feb
      */
      // STRIPE
      case 'stripe': {
        const url = (
          stripeIntentConfirmation
            ? urls.stripeConfirmation
            : urls.campaignParams
        ).replace(':campaignId', campaignId);
        const data = stripeIntentConfirmation ? {
          intent_id: state.donationIncludedGatewayParams.attributes?.stripe_payment_intent_id,
          campaign_id: state.campaign.data.id,
        } : reqData;

        axios
          .post(url, data, config)
          .then(response => {
            commit('setStripeIdempotencyKey', nanoid());

            // open Thank you page if intent confirmation result "ok"
            if ((stripeIntentConfirmation && response.data.Result === 'ok')) {
              window.postMessage({
                type: 'gateway-response-success',
              }, '*');
              return;
            }

            const { attributes } = response.data.data;

            if (attributes?.parameters?.required_action) {
              // Use Stripe.js to handle required card action
              dispatch('handleCardAction', attributes.parameters);
            }

            if (attributes.successful) {
              commit('setIsSubmitted', false);

              window.postMessage({
                type: 'gateway-response-success',
              }, '*');
            } else if (attributes.error_message) {
              // show Error notification message
              commit('setCaptchaToken', '');
              const tk = `errors.stripe_${attributes.error_code_str_gateway}`
              commit('setError', {
                title: 'Stripe Error!',
                translationKey: [tk, attributes.error_message],
              });
              commit('setIsSubmitted', false);
            }
          })
          .catch(error => {
            commit('setIsSubmitted', false);
            commit('setCaptchaToken', '');
            const errtk = error?.response?.data.error_translation_key;
            const errmsg = error?.response?.data.error;
            commit('setError', {
              title: 'Stripe Error!',
              translationKey: [errtk, errmsg],
            });
            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }
      // FOUNDATIONS
      case 'fund': {
        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const { successful, error_message } = response.data.data.attributes;

            if (!successful) throw createError(error_message, response.data.data.attributes);

            commit('setIsSubmitted', false);

            window.postMessage({
              type: 'gateway-response-success',
            }, '*');
          })
          .catch(error => {
            commit('setIsSubmitted', false);
            commit('setCaptchaToken', '');

            const errtk = error?.tk
              ? error?.translation_key
              : error?.response?.data.error_translation_key;
            const errmsg = error?.custom
              ? error?.message
              : error?.response?.data?.error;
            commit('setError', {
              title: 'Foundation Error!',
              translationKey: [errtk, errmsg],
            });

            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }
      //  BROOM FOUNDATION
      case 'broomfoundation': {
        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const { successful, error_message } = response.data.data.attributes;

            if (!successful) throw createError(error_message, response.data.data.attributes);

            commit('setIsSubmitted', false);
            commit('setDialogTitle', 'Broom Foundation');
            commit('setDialogIframeSrc', response.data.data.attributes.parameters.url);
            commit('setDialogVisibleStatus', true);
          })
          .catch(error => {
            commit('setIsSubmitted', false);
            commit('setCaptchaToken', '');

            const errtk = error?.tk
              ? error?.translation_key
              : error?.response?.data.error_translation_key;
            const errmsg = error?.custom
              ? error?.message
              : error?.response?.data?.error;
            commit('setError', {
              title: 'BroomFoundation Error!',
              translationKey: [errtk, errmsg],
            });

            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }
      //  MH TRUST
      case 'mhtrust': {
        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const { successful, error_message } = response.data.data.attributes;

            if (!successful) throw createError(error_message, response.data.data.attributes);

            commit('setIsSubmitted', false);
            commit('setDialogTitle', 'MH Trust');
            commit('setDialogIframeSrc', response.data.data.attributes.parameters.url);
            commit('setDialogVisibleStatus', true);
          })
          .catch(error => {
            commit('setIsSubmitted', false);
            commit('setCaptchaToken', '');

            const errtk = error?.tk
              ? error?.translation_key
              : error?.response?.data.error_translation_key;
            const errmsg = error?.custom
              ? error?.message
              : error?.response?.data?.error;
            commit('setError', {
              title: 'MH Trust Error!',
              translationKey: [errtk, errmsg],
            });

            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }
      // ZOREYA TZEDOKOS
      case 'zoreyatzedokos': {
        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const { successful, error_message } = response.data.data.attributes;

            if (!successful) throw createError(error_message, response.data.data.attributes);

            commit('setIsSubmitted', false);
            commit('setDialogTitle', 'Zoreya Tzedokos');
            commit('setDialogIframeSrc', response.data.data.attributes.parameters.url);
            commit('setDialogVisibleStatus', true);
          })
          .catch(error => {
            commit('setIsSubmitted', false);
            commit('setCaptchaToken', '');

            const errtk = error?.tk
              ? error?.translation_key
              : error?.response?.data.error_translation_key;
            const errmsg = error?.custom
              ? error?.message
              : error?.response?.data?.error;
            commit('setError', {
              title: 'Zoreya Tzedokos Error!',
              translationKey: [errtk, errmsg],
            });

            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }
      // YAD SHLOMO
      case 'yadshlomo': {
        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const { successful, error_message } = response.data.data.attributes;

            if (!successful) throw createError(error_message, response.data.data.attributes);

            commit('setIsSubmitted', false);
            commit('setDialogTitle', 'Yad Shlomo');
            commit('setDialogIframeSrc', response.data.data.attributes.parameters.url);
            commit('setDialogVisibleStatus', true);
          })
          .catch(error => {
            commit('setIsSubmitted', false);
            commit('setCaptchaToken', '');

            const errtk = error?.tk
              ? error?.translation_key
              : error?.response?.data.error_translation_key;
            const errmsg = error?.custom
              ? error?.message
              : error?.response?.data?.error;
            commit('setError', {
              title: 'Yad Shlomo Error!',
              translationKey: [errtk, errmsg],
            });

            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }
      // Belz Vouchers
      case 'belzvouchers': {
        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const { successful, error_message } = response.data.data.attributes;

            if (!successful) throw createError(error_message, response.data.data.attributes);

            commit('setIsSubmitted', false);
            commit('setDialogTitle', 'Belz Vouchers');
            commit('setDialogIframeSrc', response.data.data.attributes.parameters.url);
            commit('setDialogVisibleStatus', true);
          })
          .catch(error => {
            commit('setIsSubmitted', false);
            commit('setCaptchaToken', '');

            const errtk = error?.tk
              ? error?.translation_key
              : error?.response?.data.error_translation_key;
            const errmsg = error?.custom
              ? error?.message
              : error?.response?.data?.error;
            commit('setError', {
              title: 'Belz Vouchers Error!',
              translationKey: [errtk, errmsg],
            });

            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }
      // Uta
      case 'uta': {
        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const { successful, error_message } = response.data.data.attributes;

            if (!successful) throw createError(error_message, response.data.data.attributes);

            commit('setIsSubmitted', false);
            commit('setDialogTitle', 'UTA');
            commit('setDialogIframeSrc', response.data.data.attributes.parameters.url);
            commit('setDialogVisibleStatus', true);
          })
          .catch(error => {
            commit('setIsSubmitted', false);
            commit('setCaptchaToken', '');

            const errtk = error?.tk
              ? error?.translation_key
              : error?.response?.data.error_translation_key;
            const errmsg = error?.custom
              ? error?.message
              : error?.response?.data?.error;
            commit('setError', {
              title: 'UTA Error!',
              translationKey: [errtk, errmsg],
            });

            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }
      // PayPalV2
      case 'paypalv2': {
        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const { successful, error_message } = response.data.data.attributes;

            if (!successful) throw createError(error_message, response.data.data.attributes);

            const pp2Resolve = payPalV2.getResolve()
            if (pp2Resolve) {
              const donationId = response.data.data.attributes.donation_id;
              payPalV2.setDonationID(donationId)
              pp2Resolve({
                orderID: response.data.data.attributes.parameters.order_id,
                error: '',
              })
            }

            commit('setIsSubmitted', false);
          })
          .catch(error => {
            commit('setIsSubmitted', false);
            commit('setCaptchaToken', '');

            const errtk = error?.tk
              ? error?.translation_key
              : error?.response?.data.error_translation_key;
            const errmsg = error?.custom
              ? error?.message
              : error?.response?.data?.error;
            commit('setError', {
              title: 'PayPal Error!',
              translationKey: [errtk, errmsg],
            });

            const pp2Resolve = payPalV2.getResolve()
            if (pp2Resolve) {
              pp2Resolve({ orderID: '', error: errmsg })
            }

            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }
      // cmz
      case 'cmz': {
        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const { successful, error_message } = response.data.data.attributes;

            if (!successful) throw createError(error_message, response.data.data.attributes);

            commit('setIsSubmitted', false);
            commit('setDialogTitle', 'CMZ');
            commit('setDialogIframeSrc', response.data.data.attributes.parameters.url);
            commit('setDialogVisibleStatus', true);
          })
          .catch(error => {
            commit('setIsSubmitted', false);
            commit('setCaptchaToken', '');

            const errtk = error?.tk
              ? error?.translation_key
              : error?.response?.data.error_translation_key;
            const errmsg = error?.custom
              ? error?.message
              : error?.response?.data?.error;
            commit('setError', {
              title: 'CMZ Error!',
              translationKey: [errtk, errmsg],
            });

            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }
      // Three Pillars
      case 'threepillars': {
        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const { successful, error_message } = response.data.data.attributes;

            if (!successful) throw createError(error_message, response.data.data.attributes);

            commit('setIsSubmitted', false);
            commit('setDialogTitle', 'Three Pillars');
            commit('setDialogIframeSrc', response.data.data.attributes.parameters.url);
            commit('setDialogVisibleStatus', true);
          })
          .catch(error => {
            commit('setIsSubmitted', false);
            commit('setCaptchaToken', '');

            const errtk = error?.tk
              ? error?.translation_key
              : error?.response?.data.error_translation_key;
            const errmsg = error?.custom
              ? error?.message
              : error?.response?.data?.error;
            commit('setError', {
              title: 'Three Pillars Error!',
              translationKey: [errtk, errmsg],
            });

            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }
      // PayFast
      case 'payfast': {
        removeBeforeUnloadListener();

        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const redirectConfig = state.donationParams.data.attributes.config?.redirect_config;
            const { successful, error_message } = response.data.data.attributes;

            if (!successful) throw createError(error_message, response.data.data.attributes);

            const { url } = response.data.data.attributes.parameters;

            if (redirectConfig?.open_popup_on_desktop && !Vue.prototype.$isMobile()) {
              const popup = popupWindow(url, 'PayFast', 600, 600);

              commit('setOverlay', true);
              commit('setOverlayRedirectLink', url);

              const timer = setInterval(() => {
                if (popup && popup.closed) {
                  commit('setIsSubmitted', false);
                  commit('setOverlay', false);
                  clearInterval(timer);
                }
              }, 500);

              window.onfocus = () => (popup ? popup.focus() : false);

              return;
            }

            window.parent.location.href = url;
          })
          .catch(error => {
            commit('setIsSubmitted', false);
            commit('setCaptchaToken', '');

            const errtk = error?.tk
              ? error?.translation_key
              : error?.response?.data.error_translation_key;
            const errmsg = error?.custom
              ? error?.message
              : error?.response?.data?.error;
            commit('setError', {
              title: 'PayFast Error!',
              translationKey: [errtk, errmsg],
            });

            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }
      // MercadoPago
      case 'mercadopago': {
        removeBeforeUnloadListener();

        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const redirectConfig = state.donationParams.data.attributes.config?.redirect_config;
            const { data } = response;
            const { successful, error_message } = data.data.attributes;

            if (!successful) throw createError(error_message, data.data.attributes);

            const { url } = data.data.attributes.parameters;

            if (redirectConfig?.open_popup_on_desktop && !Vue.prototype.$isMobile()) {
              const popup = popupWindow(url, 'MercadoPago', 600, 600);

              commit('setOverlay', true);
              commit('setOverlayRedirectLink', url);

              const timer = setInterval(() => {
                if (popup && popup.closed) {
                  commit('setIsSubmitted', false);
                  commit('setOverlay', false);
                  clearInterval(timer);
                }
              }, 500);

              window.onfocus = () => (popup ? popup.focus() : false);

              return;
            }

            window.parent.location.href = url;
          })
          .catch(error => {
            commit('setIsSubmitted', false);
            commit('setCaptchaToken', '');

            const errtk = error?.tk
              ? error?.translation_key
              : error?.response?.data.error_translation_key;
            const errmsg = error?.custom
              ? error?.message
              : error?.response?.data?.error;
            commit('setError', {
              title: 'MercadoPago Error!',
              translationKey: [errtk, errmsg],
            });

            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }
      case 'mercadopago-direct': {
        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const { data } = response;
            const { successful, error_message } = data.data.attributes;

            if (!successful) throw createError(error_message, data.data.attributes);

            window.postMessage({
              type: 'gateway-response-success',
            }, '*');
            commit('setIsSubmitted', false);
          })
          .catch((error: string | any) => {
            commit('setCaptchaToken', '');
            commit('setIsSubmitted', false);
            commit('setOverlay', false);

            const [errtk, errmsg] = parseError(error, 'stripe_sepa_direct_debit')

            commit('setError', {
              title: 'MercadoPago Error!',
              translationKey: [errtk, errmsg],
            });
            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }
      // Kol Yom
      case 'kolyom': {
        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const { successful, error_message } = response.data.data.attributes;

            if (!successful) throw createError(error_message, response.data.data.attributes);

            commit('setIsSubmitted', false);
            commit('setDialogTitle', 'Kol Yom');
            commit('setDialogIframeSrc', response.data.data.attributes.parameters.url);
            commit('setDialogVisibleStatus', true);
          })
          .catch(error => {
            commit('setIsSubmitted', false);
            commit('setCaptchaToken', '');

            const errtk = error?.tk
              ? error?.translation_key
              : error?.response?.data.error_translation_key;
            const errmsg = error?.custom
              ? error?.message
              : error?.response?.data?.error;
            commit('setError', {
              title: 'Kol Yom Error!',
              translationKey: [errtk, errmsg],
            });

            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }
      // Kcbobov
      case 'kcbobov': {
        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const { successful, error_message } = response.data.data.attributes;

            if (!successful) throw createError(error_message, response.data.data.attributes);

            commit('setIsSubmitted', false);
            commit('setDialogTitle', 'Kcbobov');
            commit('setDialogIframeSrc', response.data.data.attributes.parameters.url);
            commit('setDialogVisibleStatus', true);
          })
          .catch(error => {
            commit('setIsSubmitted', false);
            commit('setCaptchaToken', '');

            const errtk = error?.tk
              ? error?.translation_key
              : error?.response?.data.error_translation_key;
            const errmsg = error?.custom
              ? error?.message
              : error?.response?.data?.error;
            commit('setError', {
              title: 'Kcbobov Error!',
              translationKey: [errtk, errmsg],
            });

            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }
      // Tevini
      case 'tevini': {
        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const { successful, error_message } = response.data.data.attributes;

            if (!successful) throw createError(error_message, response.data.data.attributes);

            commit('setIsSubmitted', false);
            commit('setDialogTitle', 'Tevini');
            commit('setDialogIframeSrc', response.data.data.attributes.parameters.url);
            commit('setDialogVisibleStatus', true);
          })
          .catch(error => {
            commit('setIsSubmitted', false);
            commit('setCaptchaToken', '');

            const errtk = error?.tk
              ? error?.translation_key
              : error?.response?.data.error_translation_key;
            const errmsg = error?.custom
              ? error?.message
              : error?.response?.data?.error;
            commit('setError', {
              title: 'Tevini Error!',
              translationKey: [errtk, errmsg],
            });

            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }
      //  ASSERBISHVIL
      case 'asserbishvil': {
        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const { successful, error_message } = response.data.data.attributes;

            if (!successful) throw createError(error_message, response.data.data.attributes);

            commit('setIsSubmitted', false);
            commit('setDialogTitle', 'Asser Bishvil');
            commit('setDialogIframeSrc', response.data.data.attributes.parameters.url);
            commit('setDialogVisibleStatus', true);
          })
          .catch(error => {
            commit('setCaptchaToken', '');
            commit('setIsSubmitted', false);

            const errtk = error?.tk
              ? error?.translation_key
              : error?.response?.data.error_translation_key;
            const errmsg = error?.custom
              ? error?.message
              : error?.response?.data?.error;
            commit('setError', {
              title: 'Asserbishvil Error!',
              translationKey: [errtk, errmsg],
            });
            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }
      // YAADPAY
      case 'yaadpay': {
        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(({ data }) => {
            const { successful, error_message, parameters } = data.data.attributes;

            if (!successful) throw createError(error_message, data.data.attributes);

            const donationId = data.data.attributes.donation_id;
            const attr = { ...state.donationData }.attributes;
            const { config } = state.donationParams.data.attributes;
            // const { installment } = attr;
            let installmentTm = attr.installment_tm_option;
            const paymentConfigAttr = state.paymentConfig.attributes;
            const campaignAttr = state.campaign.data.attributes;
            const lang = rootState.translation.locate;
            const pageLangMap = new Map<string, string>([['he', 'HEB'], ['en', 'ENG']])

            const currenciesNumbers: { [key: string]: number } = {
              ils: 1,
              usd: 2,
              eur: 3,
              gbp: 4,
            }

            const currency = currenciesNumbers[attr.currency];

            if (currency !== 1) {
              installmentTm = false;
            }

            let amt = parameters.charge_currency_amount;
            // const instAmt = Math.floor(amt / (attr.installment_period || 1))
            // amt = installment && installmentTm ? amt : instAmt;

            let postpone = !installmentTm ? 'False' : 'True';
            let pageLang = pageLangMap.get(lang) || 'ENG'

            if (config.type === 2) {
              postpone = 'False';
            }

            const gatewayModeConfig = config.gateway_mode_config?.value && config.gateway_mode_config?.gateway_mode?.[state.paymentMethod]

            if (
              gatewayModeConfig === '1'
              || paymentConfigAttr.configuration.mode === '1'
            ) {
              postpone = 'False';
              amt = 1
              pageLang = 'HEB'
            }

            const { billing_first_name: bfn, billing_last_name: bln, receipt_name: rn } = attr;
            const [fn, ...ln] = (rn || attr.billing_name || `${bfn} ${bln}`).replace(/[`'"]+/g, '').split(' ');

            // phone format is too long for YaadPay
            const shortPhone = attr.phone.replace(/[+\- ]+/g, '');

            let UserId = state.donationData.attributes.israeli_zehut;
            if (UserId === '') {
              UserId = `L00${donationId}`
            }

            const YaadFieldsInfoJSON = rootState.donation.paymentConfig.attributes.configuration?.YaadFieldsInfo || ''

            let yaadFields;
            try {
              yaadFields = JSON.parse(YaadFieldsInfoJSON);
            } catch {
              yaadFields = {};
            }

            const heshDesc = replaceVarsInGatewayFields(yaadFields?.heshDesc || '', rootState) || '';

            const params = [
              'action=pay',
              `Coin=${currency}`,
              `Amount=${amt}`,
              `ClientName=${fn}`,
              `ClientLName=${ln.join(' ')}`,
              `UserId=${UserId}`,
              `Order=${donationId}`,
              `Info=${campaignAttr.title} ${paymentConfigAttr.configuration.prefix} ${donationId}`,
              `PassP=${paymentConfigAttr.configuration.password_public}`,
              `Masof=${paymentConfigAttr.configuration.terminal_id}`,
              `Postpone=${postpone}`,
              `email=${attr.email}`,
              `street=${attr.address_line1} ${attr.address_line2}`,
              `city=${attr.address_city}`,
              `zip=${attr.address_zip}`,
              `phone=${encodeURIComponent(shortPhone)}`,
              `PageLang=${pageLang}`,
              'sendemail=False',
              'UTF8=True',
              'UTF8out=True',
              'MoreData=True',
              'Sign=True',
              'FirstDate=',
              'freq=1',
              'SendHesh=True',
              `heshDesc=${heshDesc}`,
            ];

            if (
              gatewayModeConfig === '1'
              || paymentConfigAttr.configuration.mode === '1'
            ) {
              params.push(
                'J5=True',
                'Temp=X',
                'tmp=3',
              )
            }

            if (attr.sms_receipt) {
              params.push(
                `cell=${encodeURIComponent(attr.phone)}`,
                'sendHeshSMS=True',
              )
            }

            let url = `https://icom.yaad.net/p3/?${params.join('&')}`;

            if (attr.recurring_period > 1) {
              url += `&FixTash=False&Tash=${attr.recurring_period}&HK=True&OnlyOnApprove=True`;
            }
            if (attr.installment_period > 1) {
              const hk = installmentTm ? 'False' : 'True';

              const installmentParams = [
                `Tash=${attr.installment_period}`,
                `OnlyOnApprove=${hk}`,
              ]

              installmentParams.push(...[
                `FixTash=${installmentTm ? 'True' : 'False'}`,
                `HK=${hk}`,
              ])

              url += `&${installmentParams.join('&')}`;
            }
            if (attr.recurring_period === 0 && attr.installment_period === 1) {
              url += '&Tash=1&HK=False&FixTash=False&OnlyOnApprove=False';
            }

            commit('setIsSubmitted', false);
            commit('setDialogTitle', 'Yaadpay');
            commit('setDialogIframeSrc', url);
            commit('setDialogVisibleStatus', true);
          })
          .catch(error => {
            commit('setCaptchaToken', '');
            commit('setIsSubmitted', false);

            const errtk = error?.tk
              ? error?.translation_key
              : error?.response?.data.error_translation_key;
            const errmsg = error?.custom
              ? error?.message
              : error?.response?.data?.error;
            commit('setError', {
              title: 'YaadPay Error!',
              translationKey: [errtk, errmsg],
            });
            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }
      // ISRAELTOREMET
      case 'israeltoremet': {
        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(({ data }) => {
            const { successful, error_message, parameters } = data.data.attributes;

            if (!successful) throw createError(error_message, data.data.attributes);

            const attr = { ...state.donationData }.attributes;
            const donationConfig = { ...state.donationParams }.data.attributes.config;
            const paymentConfigAttr = state.paymentConfig.attributes;
            const lang = rootState.translation.locate;
            let installmentTm = attr.installment_tm_option;
            const sum = parameters.charge_currency_amount;
            const { israeltoremet_url_type } = paymentConfigAttr.configuration;

            const currenciesNumbers: { [key: string]: number } = {
              ils: 1,
              usd: 2,
              cad: 3,
              gbp: 4,
              eur: 5,
              aud: 6,
              chf: 17,
              rub: 18,
              arc: 35,
              uah: 36,
              mxn: 37,
            }

            const currency = currenciesNumbers[attr.currency];

            /*
              Enter the amount to donate.
              If it's a recurring or installments donation,
              enter the total amount of all the recurring or split donations.
              So if it's $10/month for 12 months, enter $120.
              If it's 100 ILS split to 10 months, enter 100.
            */

            // multiplying the amount from backend to number of recurring
            // if (attr.recurring) {
            //   sum *= attr.recurring_period;
            // }
            // multiplying the amount from backend to number of installments
            // if (attr.installment) {
            //   sum *= attr.installment_period
            // }

            const { billing_first_name: bfn, billing_last_name: bln, receipt_name: rn } = attr;
            const [fn, ...ln] = (rn || attr.billing_name || `${bfn} ${bln}`).replace(/[`'"]+/g, '').split(' ');

            let params = [
              'MakeDonation=1',
              'webhook=3',
              `AmutaGovId=${paymentConfigAttr.configuration.terminal_id}`,
              `projectid=${paymentConfigAttr.configuration.project}`,
              'fb=1',
              `CF1=${data.data.attributes.donation_id}`,
              `CF2=${data.data.attributes.donation_id}`,
              `CF3=${attr.campaign_id}`,
              `CampaignId=${attr.campaign_id}`,
              'step=2',
              `sum=${sum}`,
              `fname=${fn}`,
              `lname=${ln.join(' ')}`,
              `email=${attr.email}`,
              `currency=${currency}`,
              'post=1',
              'showcontrols=',
              'tashlist=',
            ];

            if (currency !== 1) {
              installmentTm = false;
            }

            if (attr.installment_period > 1) {
              params = params.concat([
                `installments=${attr.installment_period}`,
                `tash=${attr.installment_period}`,
                `realtash=${installmentTm ? 1 : 0}`,
                `frequency=${installmentTm ? 1 : 2}`,
                'auth_only=0',
              ])
            } else if (attr.recurring_period > 1) {
              params = params.concat([
                `installments=${attr.recurring_period}`,
                `tash=${attr.recurring_period}`,
                'realtash=0',
                'frequency=2',
                'auth_only=0',
              ])
            } else {
              params = params.concat([
                'tash=1',
                'realtash=0',
                'frequency=1',
                'auth_only=0',
              ])
            }

            params = params.concat([
              `successurl=${encodeURIComponent(parameters.success_redirect_url)}`,
              `failurl=${encodeURIComponent(parameters.error_redirect_url)}`,
            ])

            if (attr.mail_receipt) {
              const countryObj = countryList.find(
                el => (
                  el.iso_code.toLowerCase() === attr.address_country?.toLowerCase()
                ),
              );

              if (countryObj?.id) {
                params = params.concat([
                  `countryid=${countryObj.id}`,
                ])

                const stateObj = stateList.find(
                  el => (
                    el.country_id === countryObj.id
                    && el.name.toLowerCase() === attr.address_state.toLowerCase()
                  ),
                )

                if (stateObj?.state_id) {
                  params = params.concat([
                    `stateid=${stateObj.state_id}`,
                  ])
                }
              }

              params = params.concat([
                `zipcode=${attr.address_zip}`,
                `address=${attr.address_line1} ${attr.address_line2}`,
                `city=${attr.address_city}`,
              ])
            }

            const ut = ['donate', 'pay'].find(v => v === israeltoremet_url_type) || 'donate';

            const l = ['en', 'he', 'es', 'fr'].find(v => v === lang) || donationConfig.israeltoremet_default_lang || 'he';

            const url = `https://secured.israelgives.org/${l}/${ut}/makedonation?${params.join('&')}`;
            commit('setDialogTitle', 'israeltoremet');
            commit('setDialogIframeSrc', url);
            commit('setDialogVisibleStatus', true);
          })
          .catch(error => {
            commit('setIsSubmitted', false);
            commit('setCaptchaToken', '');

            const errtk = error?.tk
              ? error?.translation_key
              : error?.response?.data.error_translation_key;
            const errmsg = error?.custom
              ? error?.message
              : error?.response?.data?.error;
            commit('setError', {
              title: 'IsraelToremet Error!',
              translationKey: [errtk, errmsg],
            });
            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }
      // MESHULAM
      case 'meshulam-bit':
      case 'meshulam-v2':
      case 'meshulam-v2-bit':
      case 'meshulam':
      case 'meshulam-google-pay': {
        setDonationDataToLoacalStarage(reqData);

        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(({ data }) => {
            const { successful, error_message, parameters } = data.data.attributes;

            if (!successful) throw createError(error_message, data.data.attributes);

            const url = parameters.redirect_url;
            commit('setDialogTitle', [`template.${state.paymentMethod}_dialog_title`, 'Meshulam']);
            commit('setDialogIframeSrc', url);
            commit('setDialogVisibleStatus', true);
          })
          .catch(error => {
            commit('setIsSubmitted', false);
            commit('setCaptchaToken', '');

            const errtk = error?.tk
              ? error?.translation_key
              : error?.response?.data.error_translation_key;
            const errmsg = error?.custom
              ? error?.message
              : error?.response?.data?.error;
            commit('setError', {
              title: 'Meshulam Error!',
              translationKey: [errtk, errmsg],
            });
            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }
      // PAY PAL
      case 'paypal': {
        setDonationDataToLoacalStarage(reqData);
        removeBeforeUnloadListener();

        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const redirectConfig = state.donationParams.data.attributes.config?.redirect_config;
            const { data } = response;
            const { successful, error_message } = data.data.attributes;

            if (!successful) throw createError(error_message, data.data.attributes);

            const { url } = data.data.attributes.parameters;

            if (redirectConfig?.open_popup_on_desktop && !Vue.prototype.$isMobile()) {
              const popup = popupWindow(url, 'PayPal', 600, 600);

              commit('setOverlay', true);
              commit('setOverlayRedirectLink', url);

              const timer = setInterval(() => {
                if (popup && popup.closed) {
                  commit('setIsSubmitted', false);
                  commit('setOverlay', false);
                  clearInterval(timer);
                }
              }, window.Cypress ? 2000 : 500);

              window.onfocus = () => (popup ? popup.focus() : false);

              return;
            }

            window.parent.location.href = url;
          })
          .catch((error: string | any) => {
            commit('setCaptchaToken', '');
            commit('setIsSubmitted', false);
            commit('setOverlay', false);

            const errtk = error?.tk
              ? error?.translation_key
              : error?.response?.data.error_translation_key;
            const errmsg = error?.custom
              ? error?.message
              : error?.response?.data?.error;
            commit('setError', {
              title: 'PayPal Error!',
              translationKey: [errtk, errmsg],
            });
            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }
      // STRIPE SEPA DEBIT
      case 'stripe-sepa-direct-debit': {
        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const { data } = response;
            const { successful, error_message } = data.data.attributes;

            if (!successful) throw createError(error_message, data.data.attributes);

            window.postMessage({
              type: 'gateway-response-success',
            }, '*');
            commit('setIsSubmitted', false);
          })
          .catch((error: string | any) => {
            commit('setCaptchaToken', '');
            commit('setIsSubmitted', false);
            commit('setOverlay', false);

            const [errtk, errmsg] = parseError(error, 'stripe_sepa_direct_debit')

            commit('setError', {
              title: 'Stripe SEPA Debit Error!',
              translationKey: [errtk, errmsg],
            });
            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }
      // OJC
      case 'ojc': {
        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const { data } = response;
            const { successful, error_message } = data.data.attributes;

            if (!successful) throw createError(error_message, data.data.attributes);

            window.postMessage({
              type: 'gateway-response-success',
            }, '*');
            commit('setIsSubmitted', false);
          })
          .catch((error: string | any) => {
            commit('setCaptchaToken', '');
            commit('setIsSubmitted', false);
            commit('setOverlay', false);

            const errtk = error?.tk
              ? error?.translation_key
              : error?.response?.data.error_translation_key;
            const errmsg = error?.custom
              ? error?.message
              : error?.response?.data?.error;
            commit('setError', {
              title: 'OJC Error!',
              translationKey: [errtk, errmsg],
            });
            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }
      // Matbia
      case 'matbia': {
        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const { data } = response;
            const { successful, error_message } = data.data.attributes;

            if (!successful) throw createError(error_message, data.data.attributes);

            window.postMessage({
              type: 'gateway-response-success',
            }, '*');
            commit('setIsSubmitted', false);
          })
          .catch((error: string | any) => {
            commit('setCaptchaToken', '');
            commit('setIsSubmitted', false);
            commit('setOverlay', false);

            const errtk = error?.tk
              ? error?.translation_key
              : error?.response?.data.error_translation_key;
            const errmsg = error?.custom
              ? error?.message
              : error?.response?.data?.error;
            commit('setError', {
              title: 'Matbia Error!',
              translationKey: [errtk, errmsg],
            });
            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }
      // Pledger
      case 'pledger-direct': {
        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const { data } = response;
            const { successful, error_message } = data.data.attributes;

            if (!successful) throw createError(error_message, data.data.attributes);

            window.postMessage({
              type: 'gateway-response-success',
            }, '*');
            commit('setIsSubmitted', false);
          })
          .catch((error: string | any) => {
            commit('setCaptchaToken', '');
            commit('setIsSubmitted', false);
            commit('setOverlay', false);

            const errtk = error?.tk
              ? error?.translation_key
              : error?.response?.data.error_translation_key;
            const errmsg = error?.custom
              ? error?.message
              : error?.response?.data?.error;
            commit('setError', {
              title: 'Pledger Error!',
              translationKey: [errtk, errmsg],
            });
            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }
      // CHECKOUT.FI
      case 'checkout-fi': {
        setDonationDataToLoacalStarage(reqData);
        removeBeforeUnloadListener();

        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const redirectConfig = state.donationParams.data.attributes.config?.redirect_config;
            const { data } = response;
            const { successful, error_message, parameters } = data.data.attributes;

            if (!successful) throw createError(error_message, data.data.attributes);

            if (redirectConfig?.open_popup_on_desktop && !Vue.prototype.$isMobile()) {
              const popup = popupWindow(parameters.checkout_fi_href, 'Paytrail', 600, 600);

              commit('setOverlay', true);
              commit('setOverlayRedirectLink', parameters.checkout_fi_href);

              if (popup && !popup.closed) {
                const timer = setInterval(() => {
                  if (popup && popup.closed) {
                    commit('setIsSubmitted', false);
                    commit('setOverlay', false);
                    clearInterval(timer);
                  }
                }, 500);
              }

              window.onfocus = () => (popup ? popup.focus() : false);

              return;
            }

            window.parent.location.href = parameters.checkout_fi_href;
          })
          .catch((error: string | any) => {
            commit('setCaptchaToken', '');
            commit('setIsSubmitted', false);
            commit('setOverlay', false);

            const errtk = error?.tk
              ? error?.translation_key
              : error?.response?.data.error_translation_key;
            const errmsg = error?.custom
              ? error?.message
              : error?.response?.data?.error;
            commit('setError', {
              title: 'Paytrail Error!',
              translationKey: [errtk, errmsg],
            });
            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }
      // BANCONTACT
      case 'bancontact': {
        removeBeforeUnloadListener();

        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const redirectConfig = state.donationParams.data.attributes.config?.redirect_config;
            const { data } = response;
            const { successful, error_message, parameters } = data.data.attributes;

            if (!successful) throw createError(error_message, data.data.attributes);

            if (redirectConfig?.open_popup_on_desktop && !Vue.prototype.$isMobile()) {
              const popup = popupWindow(parameters.url, 'Bancontact', 600, 600);

              commit('setOverlay', true);
              commit('setOverlayRedirectLink', parameters.url);

              if (popup && !popup.closed) {
                const timer = setInterval(() => {
                  if (popup && popup.closed) {
                    commit('setIsSubmitted', false);
                    commit('setOverlay', false);
                    clearInterval(timer);
                  }
                }, 500);
              }

              window.onfocus = () => (popup ? popup.focus() : false);

              return;
            }

            window.parent.location.href = parameters.url;
          })
          .catch((error: string | any) => {
            commit('setCaptchaToken', '');
            commit('setIsSubmitted', false);
            commit('setOverlay', false);

            const errtk = error?.tk
              ? error?.translation_key
              : error?.response?.data.error_translation_key;
            const errmsg = error?.custom
              ? error?.message
              : error?.response?.data?.error;
            commit('setError', {
              title: 'Bancontact Error!',
              translationKey: [errtk, errmsg],
            });
            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }
      // BLACKBAUD
      case 'blackbaud': {
        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const { data } = response;
            const { successful, error_message } = data.data.attributes;

            if (!successful) throw createError(error_message, data.data.attributes);

            window.postMessage({
              type: 'gateway-response-success',
            }, '*');
            commit('setIsSubmitted', false);
          })
          .catch((error: string | any) => {
            commit('setCaptchaToken', '');
            commit('setIsSubmitted', false);
            commit('setOverlay', false);

            const errtk = error?.tk
              ? error?.translation_key
              : error?.response?.data.error_translation_key;
            const errmsg = error?.custom
              ? error?.message
              : error?.response?.data?.error;
            commit('setError', {
              title: 'Blackbaud Error!',
              translationKey: [errtk, errmsg],
            });
            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }
      // PLEDGEONLY
      case 'pledgeonly': {
        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const { data } = response;
            const { successful, error_message } = data.data.attributes;

            if (!successful) throw createError(error_message, data.data.attributes);

            window.postMessage({
              type: 'gateway-response-success',
            }, '*');
            commit('setIsSubmitted', false);
          })
          .catch((error: string | any) => {
            commit('setCaptchaToken', '');
            commit('setIsSubmitted', false);
            commit('setOverlay', false);

            const errtk = error?.tk
              ? error?.translation_key
              : error?.response?.data.error_translation_key;
            const errmsg = error?.custom
              ? error?.message
              : error?.response?.data?.error;
            commit('setError', {
              title: 'Pledge only Error!',
              translationKey: [errtk, errmsg],
            });
            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }
      // HK BANK TRANSFER
      case 'hk-bank-transfer': {
        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const { data } = response;
            const { successful, error_message } = data.data.attributes;

            if (!successful) throw createError(error_message, data.data.attributes);

            window.postMessage({
              type: 'gateway-response-success',
            }, '*');
            commit('setIsSubmitted', false);
          })
          .catch((error: string | any) => {
            commit('setCaptchaToken', '');
            commit('setIsSubmitted', false);
            commit('setOverlay', false);

            const errtk = error?.tk
              ? error?.translation_key
              : error?.response?.data.error_translation_key;
            const errmsg = error?.custom
              ? error?.message
              : error?.response?.data?.error;
            commit('setError', {
              title: 'HK bank transfer Error!',
              translationKey: [errtk, errmsg],
            });
            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }
      // PayArc
      case 'payarc': {
        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const { data } = response;
            const { successful, error_message } = data.data.attributes;

            if (!successful) throw createError(error_message, data.data.attributes);

            window.postMessage({
              type: 'gateway-response-success',
            }, '*');
            commit('setIsSubmitted', false);
          })
          .catch((error: string | any) => {
            commit('setCaptchaToken', '');
            commit('setIsSubmitted', false);
            commit('setOverlay', false);

            const errtk = error?.tk
              ? error?.translation_key
              : error?.response?.data.error_translation_key;
            const errmsg = error?.custom
              ? error?.message
              : error?.response?.data?.error;
            commit('setError', {
              title: 'PayArc Error!',
              translationKey: [errtk, errmsg],
            });
            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }
      // ACHISOMOCH
      case 'achisomoch': {
        removeBeforeUnloadListener();

        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const { data } = response;
            const { successful, error_message, parameters } = data.data.attributes;

            if (!successful) throw createError(error_message, data.data.attributes);

            window.parent.window.location.href = parameters.url;
            window.parent.postMessage({ type: 'achisomoch-redirect', url: parameters.url }, '*');

            commit('setIsSubmitted', false);
            // commit('setDialogTitle', 'Achisomoch');
            // commit('setDialogIframeSrc', parameters.url);
            // commit('setDialogVisibleStatus', true);
          })
          .catch((error: string | any) => {
            commit('setCaptchaToken', '');
            commit('setIsSubmitted', false);
            commit('setOverlay', false);

            const errtk = error?.tk
              ? error?.translation_key
              : error?.response?.data.error_translation_key;
            const errmsg = error?.custom
              ? error?.message
              : error?.response?.data?.error;
            commit('setError', {
              title: 'Achisomoch Error!',
              translationKey: [errtk, errmsg],
            });
            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }
      // PAYGATE
      case 'paygate': {
        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const { data } = response;
            const { successful, error_message, parameters } = data.data.attributes;

            if (!successful) throw createError(error_message, data.data.attributes);

            let params = [
              ['PAY_REQUEST_ID', parameters.pay_request_id],
              ['CHECKSUM', parameters.checksum],
            ]

            if (parameters?.url_values) {
              params = parameters.url_values.split('&').map((e: string) => {
                const [k, v] = e.split('=');
                return [k, v];
              });
            }

            const form = document.createElement('form');
            form.action = parameters.redirect_url;
            form.method = 'post';
            form.target = 'donationsIframe'
            form.append(...params.map(e => {
              const input = document.createElement('input');
              input.type = 'hidden';
              [input.name, input.value] = e;
              return input;
            }))
            document.body.append(form);

            window.addEventListener('donationsIframeLoaded', () => {
              form.submit()
            })

            commit('setIsSubmitted', false);
            commit('setDialogTitle', 'PayGate');
            commit('setDialogIframeSrc', '');
            commit('setDialogVisibleStatus', true);
          })
          .catch((error: string | any) => {
            commit('setCaptchaToken', '');
            commit('setIsSubmitted', false);
            commit('setOverlay', false);

            const errtk = error?.tk
              ? error?.translation_key
              : error?.response?.data.error_translation_key;
            const errmsg = error?.custom
              ? error?.message
              : error?.response?.data?.error;
            commit('setError', {
              title: 'PayGate Error!',
              translationKey: [errtk, errmsg],
            });
            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }
      // STRIPE ACH
      case 'stripe-ach': {
        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const { data } = response;
            const { successful, error_message } = data.data.attributes;

            if (!successful) throw createError(error_message, data.data.attributes);

            window.postMessage({
              type: 'gateway-response-success',
            }, '*');

            commit('setIsSubmitted', false);
          })
          .catch((error: string | any) => {
            commit('setCaptchaToken', '');
            commit('setIsSubmitted', false);
            commit('setOverlay', false);

            const [errtk, errmsg] = parseError(error, 'stripe_ach')

            commit('setError', {
              title: 'Stripe ACH!',
              translationKey: [errtk, errmsg],
            });
            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }
      // STRIPE iDEAL
      case 'stripe-ideal': {
        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const { data } = response;
            const { successful, error_message } = data.data.attributes;

            if (!successful) throw createError(error_message, data.data.attributes);

            dispatch('confirmIdealPayment', data.data.attributes?.parameters)
          })
          .catch((error: string | any) => {
            commit('setCaptchaToken', '');
            commit('setIsSubmitted', false);
            commit('setOverlay', false);

            const [errtk, errmsg] = parseError(error, 'stripe_ideal')

            commit('setError', {
              title: 'Stripe iDEAL!',
              translationKey: [errtk, errmsg],
            });
            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }
      // STRIPE BECS Direct Debit
      case 'stripe-becs': {
        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const { data } = response;
            const { successful, error_message } = data.data.attributes;

            if (!successful) throw createError(error_message, data.data.attributes);

            dispatch('confirmAuBecsDebitPayment', data.data.attributes?.parameters)
          })
          .catch((error: string | any) => {
            commit('setCaptchaToken', '');
            commit('setIsSubmitted', false);
            commit('setOverlay', false);

            const [errtk, errmsg] = parseError(error, 'stripe_becs')

            commit('setError', {
              title: 'Stripe BECS Direct Debit!',
              translationKey: [errtk, errmsg],
            });
            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }
      // STRIPE (APPLE/GOOGLE)-PAY
      case 'stripe-apple-pay':
      case 'stripe-google-pay': {
        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const { data } = response;
            const { successful, error_message } = data.data.attributes;

            if (!successful) throw createError(error_message, data.data.attributes);

            dispatch('confirmCardPayment', data.data.attributes?.parameters)
          })
          .catch((error: string | any) => {
            const pm = state.paymentMethod;
            const apple = pm === 'stripe-apple-pay';

            commit('setStripeIdempotencyKey', nanoid());
            commit('setCaptchaToken', '');
            commit('setIsSubmitted', false);
            commit('setOverlay', false);

            const [errtk, errmsg] = parseError(error, apple ? 'stripe_apple_pay' : 'stripe_google_pay')

            commit('setError', {
              title: `Stripe ${apple ? 'Apple' : 'Google'}Pay!`,
              translationKey: [errtk, errmsg],
            });
            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }

      case 'chariot': {
        return axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const { data } = response;
            const {
              successful, error_message, parameters, donation_id,
            } = data.data.attributes;

            if (!successful) throw createError(error_message, data.data.attributes);

            dispatch('clearError')

            return { parameters, donation_id }
          })
          .catch((error: string | any) => {
            commit('setIsSubmitted', false);
            commit('setCaptchaToken', '');

            const errtk = error?.tk
              ? error?.translation_key
              : error?.response?.data.error_translation_key;
            const errmsg = error?.custom
              ? error?.message
              : error?.response?.data?.error;
            commit('setError', {
              title: 'Chariot Error!',
              translationKey: [errtk, errmsg],
            });

            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
      }
      // dLocal
      case 'dlocal': {
        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const { successful, error_message } = response.data.data.attributes;

            if (!successful) throw createError(error_message, response.data.data.attributes);

            if (psConfig.version === '2') {
              window.postMessage({
                type: 'gateway-response-success',
              }, '*');
              return
            }

            commit('setIsSubmitted', false);
            commit('setDialogTitle', 'dLocal');
            commit('setDialogIframeSrc', response.data.data.attributes.parameters.redirect_url);
            commit('setDialogVisibleStatus', true);
          })
          .catch(error => {
            commit('setIsSubmitted', false);
            commit('setCaptchaToken', '');

            const errtk = error?.tk
              ? error?.translation_key
              : error?.response?.data.error_translation_key;
            const errmsg = error?.custom
              ? error?.message
              : error?.response?.data?.error;
            commit('setError', {
              title: 'dLocal Error!',
              translationKey: [errtk, errmsg],
            });

            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }
      // Forward
      case 'forward': {
        const { link, query_params, call_recurring_as_keva } = state.paymentConfig.attributes.configuration;

        const attrs = reqData.data.attributes;
        const [team] = getters.selectedTeams as IDonationIncluded[]
        let cdfqp = {}
        try {
          cdfqp = JSON.parse(query_params)
        } catch {
          cdfqp = {}
        }

        const { billing_first_name: bfn, billing_last_name: bln, receipt_name: rn } = attrs;
        const name = (rn || attrs.billing_name || `${bfn} ${bln}`).replace(/[`'"]+/g, '');

        const extract = ([beg, end]: [string, string]) => {
          const matcher = new RegExp(`${beg}(.*?)${end}`, 'gm');
          const normalise = (str: string) => str.slice(beg.length, end.length * -1);
          return (str: string) => str.match(matcher)?.map<string>(normalise)
        }
        const stringExtractor = extract(['{', '}']);

        const installmentsN = attrs.installment_period;
        const recurringN = attrs.recurring_period;
        const recurringMult = installmentsN ? installmentsN / (installmentsN ** 2) : recurringN;

        const amt = ((attrs.amount / 100) || 0) * (recurringMult || 1);

        const paramsEntries = Object
          .entries<string>(cdfqp)
          .map(([k, v]) => {
            let innerV: string | undefined = v;

            if (typeof v === 'string') {
              const a = attrs as any
              const variables = stringExtractor(innerV)

              variables?.forEach(el => {
                innerV = innerV
                  ?.replace('{name}', name || '')
                  .replace('{email}', attrs.email || '')
                  .replace('{phone}', attrs.phone || '')
                  .replace('{address}', `${attrs.address_line1} ${attrs.address_line2}` || '')
                  .replace('{city}', attrs.address_city || '')
                  .replace('{campaign_id}', attrs.campaign_id || '')
                  .replace('{team_id}', `${team?.id}` || '')
                  .replace('{team_name}', team?.attributes?.name || '')
                  .replace('{amount}', `${amt}` || '');

                if (a[el]) {
                  innerV = innerV?.replace(`{${el}}`, a[el])
                }

                if (innerV?.includes(`{${el}}`)) {
                  innerV = innerV?.replace(`{${el}}`, '');
                }
              })
            }
            return [k, innerV || undefined];
          });

        if (team?.id) {
          paramsEntries.push(['GroupeLock', '1']);
        }
        if (amt > 0) {
          paramsEntries.push(['AmountLock', '1']);
        }
        if (recurringN && call_recurring_as_keva === '1') {
          paramsEntries.push(['OnlyKeva', '1']);
        }

        const qp = qs.stringify(Object.fromEntries(paramsEntries));

        const url = `${link}${qp && `?${qp}`}`;

        commit('setIsSubmitted', false);
        commit('setDialogTitle', 'Forward');
        commit('setDialogIframeSrc', url);
        commit('setDialogVisibleStatus', true);
        return true;
      }
      // Payrix
      case 'payrix': {
        dispatch('payrixCreateToken').then(({ token }) => {
          commit(
            'setDonationIncludedGatewayParams',
            {
              payrix_token: token,
            },
          );

          const reqData: IDonationRequest = getReqData()

          axios
            .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
            .then(response => {
              const { data } = response;
              const { successful, error_message } = data.data.attributes;

              if (!successful) throw createError(error_message, data.data.attributes);

              window.postMessage({
                type: 'gateway-response-success',
              }, '*');
              commit('setIsSubmitted', false);
            })
            .catch((error: string | any) => {
              commit('setCaptchaToken', '');
              commit('setIsSubmitted', false);
              commit('setOverlay', false);

              const errtk = error?.tk
                ? error?.translation_key
                : error?.response?.data.error_translation_key;
              const errmsg = error?.custom
                ? error?.message
                : error?.response?.data?.error;
              commit('setError', {
                title: 'Payrix Error!',
                translationKey: [errtk, errmsg],
              });
              logger.logMakeDonationCatchError(error, errtk, errmsg);
            });
        })
        return true;
      }

      case 'donorsfund': {
        const reqData: IDonationRequest = getReqData()

        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const { data } = response;
            const { successful, error_message } = data.data.attributes;

            if (!successful) throw createError(error_message, data.data.attributes);

            window.postMessage({
              type: 'gateway-response-success',
            }, '*');
            commit('setIsSubmitted', false);
          })
          .catch((error: string | any) => {
            commit('setCaptchaToken', '');
            commit('setIsSubmitted', false);
            commit('setOverlay', false);

            const errtk = error?.tk
              ? error?.translation_key
              : error?.response?.data.error_translation_key;
            const errmsg = error?.custom
              ? error?.message
              : error?.response?.data?.error;
            commit('setError', {
              title: '"The Donors Fund" Error!',
              translationKey: [errtk, errmsg],
            });
            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true
      }

      // Nedarim plus
      case 'nedarim-plus-native': {
        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const { successful, error_message } = response.data.data.attributes;

            if (!successful) throw createError(error_message, response.data.data.attributes);

            const { parameters } = response.data.data.attributes

            dispatch('NedarimInit', { parameters })

            const lang = rootState.translation.locate;
            const lng = ['en'].find(v => v === lang);

            const params = [];

            if (lng) {
              params.push(
                `language=${lng}`,
              )
            }

            const paramsStr = params.join('&')

            commit('setIsSubmitted', false);
            commit('setDialogTitle', 'Nedarim Plus');
            commit('setDialogIframeSrc', `https://www.matara.pro/nedarimplus/iframe${paramsStr && `?${paramsStr}`}`);
            commit('setDialogVisibleStatus', true);
          })
          .catch(error => {
            commit('setIsSubmitted', false);
            commit('setCaptchaToken', '');

            const errtk = error?.tk
              ? error?.translation_key
              : error?.response?.data.error_translation_key;
            const errmsg = error?.custom
              ? error?.message
              : error?.response?.data?.error;
            commit('setError', {
              title: 'Nedarim Plus Error!',
              translationKey: [errtk, errmsg],
            });

            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }

      // Nedarim Native Bit
      case 'nedarim-plus-native-bit': {
        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const { successful, error_message } = response.data.data.attributes;

            if (!successful) throw createError(error_message, response.data.data.attributes);

            const { bit_url } = response.data.data.attributes.parameters

            commit('setNedarimBitURL', bit_url)

            commit('setIsSubmitted', false);
            commit('setDialogTitle', 'Nedarim Bit');
            commit('setDialogIframeSrc', '');
            commit('setDialogVisibleStatus', true);
          })
          .catch(error => {
            commit('setIsSubmitted', false);
            commit('setCaptchaToken', '');

            const errtk = error?.tk
              ? error?.translation_key
              : error?.response?.data.error_translation_key;
            const errmsg = error?.custom
              ? error?.message
              : error?.response?.data?.error;
            commit('setError', {
              title: 'Nedarim Bit Error!',
              translationKey: [errtk, errmsg],
            });

            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }

      // Tranzila
      case 'tranzila':
      case 'tranzila-bit': {
        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const { successful, error_message } = response.data.data.attributes;

            if (!successful) throw createError(error_message, response.data.data.attributes);

            commit('setIsSubmitted', false);
            commit('setDialogTitle', 'Tranzila');
            commit('setDialogIframeSrc', response.data.data.attributes.parameters.redirect_url);
            commit('setDialogVisibleStatus', true);
          })
          .catch(error => {
            commit('setIsSubmitted', false);
            commit('setCaptchaToken', '');

            const errtk = error?.tk
              ? error?.translation_key
              : error?.response?.data.error_translation_key;
            const errmsg = error?.custom
              ? error?.message
              : error?.response?.data?.error;
            commit('setError', {
              title: 'Tranzila Error!',
              translationKey: [errtk, errmsg],
            });

            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }
      // Walletdoc
      case 'walletdoc': {
        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const { successful, error_message, parameters } = response.data.data.attributes;

            if (!successful) throw createError(error_message, response.data.data.attributes);

            const params = [
              ['id', parameters.walletdoc_redirect_url_id],
            ]

            const form = document.createElement('form');
            form.action = parameters.url;
            form.method = 'post';
            form.target = 'donationsIframe'
            form.append(...params.map(e => {
              const input = document.createElement('input');
              input.type = 'hidden';
              [input.name, input.value] = e;
              return input;
            }))
            document.body.append(form);

            window.addEventListener('donationsIframeLoaded', () => {
              form.submit()
              form.remove()
            })

            commit('setIsSubmitted', false);
            commit('setDialogTitle', 'Walletdoc');
            commit('setDialogIframeSrc', '');
            commit('setDialogVisibleStatus', true);
          })
          .catch(error => {
            commit('setIsSubmitted', false);
            commit('setCaptchaToken', '');

            const errtk = error?.tk
              ? error?.translation_key
              : error?.response?.data.error_translation_key;
            const errmsg = error?.custom
              ? error?.message
              : error?.response?.data?.error;
            commit('setError', {
              title: 'Walletdoc Error!',
              translationKey: [errtk, errmsg],
            });

            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }

      case 'walletdoc-direct': {
        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const { data } = response;
            const { successful, error_message, parameters } = data.data.attributes;

            if (!successful) throw createError(error_message, data.data.attributes);

            if (parameters.redirect_url) {
              const params = [
                ['id', parameters.id],
                ['return_url', parameters.return_url],
              ]

              const form = document.createElement('form');
              form.action = parameters.redirect_url;
              form.method = 'post';
              form.target = 'donationsIframe'
              form.append(...params.map(e => {
                const input = document.createElement('input');
                input.type = 'hidden';
                [input.name, input.value] = e;
                return input;
              }))
              document.body.append(form);

              window.addEventListener('donationsIframeLoaded', () => {
                form.submit()
                form.remove()
              })
              commit('setDialogTitle', 'Walletdoc');
              commit('setDialogIframeSrc', '');
              commit('setDialogVisibleStatus', true);
              commit('setIsSubmitted', false);
            } else {
              window.postMessage({
                type: 'gateway-response-success',
              }, '*');
              commit('setIsSubmitted', false);
            }
          })
          .catch((error: string | any) => {
            commit('setCaptchaToken', '');
            commit('setIsSubmitted', false);
            commit('setOverlay', false);

            const [errtk, errmsg] = parseError(error, 'walletdoc_direct')

            commit('setError', {
              title: 'Walletdoc Error',
              translationKey: [errtk, errmsg],
            });
            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }

      // Coinbase
      case 'coinbase': {
        removeBeforeUnloadListener();

        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const redirectConfig = state.donationParams.data.attributes.config?.redirect_config;
            const { successful, error_message } = response.data.data.attributes;

            if (!successful) throw createError(error_message, response.data.data.attributes);

            const { url } = response.data.data.attributes.parameters;

            if (redirectConfig?.open_popup_on_desktop && !Vue.prototype.$isMobile()) {
              const popup = popupWindow(url, 'Coinbase', 600, 600);

              commit('setOverlay', true);
              commit('setOverlayRedirectLink', url);

              const timer = setInterval(() => {
                if (popup && popup.closed) {
                  commit('setIsSubmitted', false);
                  commit('setOverlay', false);
                  clearInterval(timer);
                }
              }, 500);

              window.onfocus = () => (popup ? popup.focus() : false);

              return;
            }

            window.parent.location.href = url;
          })
          .catch(error => {
            commit('setIsSubmitted', false);
            commit('setCaptchaToken', '');

            const errtk = error?.tk
              ? error?.translation_key
              : error?.response?.data.error_translation_key;
            const errmsg = error?.custom
              ? error?.message
              : error?.response?.data?.error;
            commit('setError', {
              title: 'Coinbase Error!',
              translationKey: [errtk, errmsg],
            });

            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }

      // Pelecard
      case 'pelecard': {
        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const { successful, error_message } = response.data.data.attributes;

            if (!successful) throw createError(error_message, response.data.data.attributes);

            commit('setIsSubmitted', false);
            commit('setDialogTitle', 'Pelecard');
            commit('setDialogIframeSrc', response.data.data.attributes.parameters.redirect_url);
            commit('setDialogVisibleStatus', true);
          })
          .catch(error => {
            commit('setIsSubmitted', false);
            commit('setCaptchaToken', '');

            const errtk = error?.tk
              ? error?.translation_key
              : error?.response?.data.error_translation_key;
            const errmsg = error?.custom
              ? error?.message
              : error?.response?.data?.error;
            commit('setError', {
              title: 'Pelecard Error!',
              translationKey: [errtk, errmsg],
            });

            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }

      // CardCom
      case 'cardcom':
      case 'cardcom-bit': {
        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const { successful, error_message } = response.data.data.attributes;

            if (!successful) throw createError(error_message, response.data.data.attributes);

            commit('setIsSubmitted', false);
            commit('setDialogTitle', 'CardCom');
            commit('setDialogIframeSrc', response.data.data.attributes.parameters.url);
            commit('setDialogVisibleStatus', true);
          })
          .catch(error => {
            commit('setIsSubmitted', false);
            commit('setCaptchaToken', '');

            const errtk = error?.tk
              ? error?.translation_key
              : error?.response?.data.error_translation_key;
            const errmsg = error?.custom
              ? error?.message
              : error?.response?.data?.error;
            commit('setError', {
              title: 'CardCom Error!',
              translationKey: [errtk, errmsg],
            });

            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }

      // Jaffa
      case 'jaffa':
      case 'jaffa-bit': {
        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const { successful, error_message } = response.data.data.attributes;

            if (!successful) throw createError(error_message, response.data.data.attributes);

            commit('setIsSubmitted', false);
            commit('setDialogTitle', 'Jaffa');
            commit('setDialogIframeSrc', response.data.data.attributes.parameters.url);
            commit('setDialogVisibleStatus', true);
          })
          .catch(error => {
            commit('setIsSubmitted', false);
            commit('setCaptchaToken', '');

            const errtk = error?.tk
              ? error?.translation_key
              : error?.response?.data.error_translation_key;
            const errmsg = error?.custom
              ? error?.message
              : error?.response?.data?.error;
            commit('setError', {
              title: 'Jaffa Error!',
              translationKey: [errtk, errmsg],
            });

            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }

      // iCredit rivhit
      case 'icredit-rivhit': {
        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const { successful, error_message } = response.data.data.attributes;

            if (!successful) throw createError(error_message, response.data.data.attributes);

            commit('setIsSubmitted', false);
            commit('setDialogTitle', 'iCredit');
            commit('setDialogIframeSrc', response.data.data.attributes.parameters.url);
            commit('setDialogVisibleStatus', true);
          })
          .catch(error => {
            commit('setIsSubmitted', false);
            commit('setCaptchaToken', '');

            const errtk = error?.tk
              ? error?.translation_key
              : error?.response?.data.error_translation_key;
            const errmsg = error?.custom
              ? error?.message
              : error?.response?.data?.error;
            commit('setError', {
              title: 'iCredit Error!',
              translationKey: [errtk, errmsg],
            });

            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }

      // asaas
      case 'asaas': {
        removeBeforeUnloadListener();

        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const redirectConfig = state.donationParams.data.attributes.config?.redirect_config;
            const { successful, error_message } = response.data.data.attributes;

            if (!successful) throw createError(error_message, response.data.data.attributes);

            const { url } = response.data.data.attributes.parameters;

            if (redirectConfig?.open_popup_on_desktop && !Vue.prototype.$isMobile()) {
              const popup = popupWindow(url, 'Coinbase', 600, 600);

              commit('setOverlay', true);
              commit('setOverlayRedirectLink', url);

              const timer = setInterval(() => {
                if (popup && popup.closed) {
                  commit('setIsSubmitted', false);
                  commit('setOverlay', false);
                  clearInterval(timer);
                }
              }, 500);

              window.onfocus = () => (popup ? popup.focus() : false);

              return;
            }

            window.parent.location.href = url;
          })
          .catch(error => {
            commit('setIsSubmitted', false);
            commit('setCaptchaToken', '');

            const errtk = error?.tk
              ? error?.translation_key
              : error?.response?.data.error_translation_key;
            const errmsg = error?.custom
              ? error?.message
              : error?.response?.data?.error;
            commit('setError', {
              title: 'ASAAS Error!',
              translationKey: [errtk, errmsg],
            });

            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }

      // Aminut
      case 'aminut': {
        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const { successful, error_message } = response.data.data.attributes;

            if (!successful) throw createError(error_message, response.data.data.attributes);

            commit('setIsSubmitted', false);
            commit('setDialogTitle', 'Aminut');
            commit('setDialogIframeSrc', response.data.data.attributes.parameters.url);
            commit('setDialogVisibleStatus', true);
          })
          .catch(error => {
            commit('setIsSubmitted', false);
            commit('setCaptchaToken', '');

            const errtk = error?.tk
              ? error?.translation_key
              : error?.response?.data.error_translation_key;
            const errmsg = error?.custom
              ? error?.message
              : error?.response?.data?.error;
            commit('setError', {
              title: 'Aminut Error!',
              translationKey: [errtk, errmsg],
            });

            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }

      // KEHILOT
      case 'kehilot':
      case 'kehilot-bit': {
        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const { data } = response;
            const { successful, error_message, parameters } = data.data.attributes;

            if (!successful) throw createError(error_message, response.data.data.attributes);

            commit('setIsSubmitted', false);
            commit('setDialogTitle', 'Kehilot');
            commit('setDialogIframeSrc', parameters.url);
            commit('setDialogVisibleStatus', true);
          })
          .catch(error => {
            commit('setIsSubmitted', false);
            commit('setCaptchaToken', '');

            const errtk = error?.tk
              ? error?.translation_key
              : error?.response?.data.error_translation_key;
            const errmsg = error?.custom
              ? error?.message
              : error?.response?.data?.error;
            commit('setError', {
              title: 'Kehilot Error!',
              translationKey: [errtk, errmsg],
            });

            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }

      // Blink Fidelipay
      case 'blink-fidelipay':
      case 'blink-fidelipay-direct': {
        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const { successful, error_message, parameters } = response.data.data.attributes;

            if (!successful) throw createError(error_message, response.data.data.attributes);

            const { origin, pathname, searchParams } = new URL(parameters.url);

            const form = document.createElement('form');
            form.action = `${origin}${pathname}`;
            form.method = 'post';
            form.target = 'donationsIframe'

            searchParams.forEach((v, k) => {
              const input = document.createElement('input');
              input.type = 'hidden';
              input.name = k;
              input.value = v;
              form.append(input)
            })

            document.body.append(form);

            window.addEventListener('donationsIframeLoaded', () => {
              form.submit()
              form.remove()
            })

            commit('setIsSubmitted', false);
            commit('setDialogTitle', 'Blink Fidelipay');
            commit('setDialogIframeSrc', '');
            commit('setDialogVisibleStatus', true);
          })
          .catch(error => {
            commit('setIsSubmitted', false);
            commit('setCaptchaToken', '');

            const errtk = error?.tk
              ? error?.translation_key
              : error?.response?.data.error_translation_key;
            const errmsg = error?.custom
              ? error?.message
              : error?.response?.data?.error;
            commit('setError', {
              title: 'Blink Fidelipay!',
              translationKey: [errtk, errmsg],
            });

            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }

      // cardknox
      case 'cardknox-google-apple-pay':
      case 'cardknox': {
        getToken(reqData.data.attributes.captcha_token)
          .then(([token, err]) => {
            if (err !== null) {
              commit('setError', {
                title: 'Captcha error!',
                translationKey: [...err],
              });
              return
            }

            commit('setCaptchaToken', token);

            const reqData: IDonationRequest = getReqData()

            axios
              .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
              .then(response => {
                const { successful, error_message } = response.data.data.attributes;

                if (!successful) throw createError(error_message, response.data.data.attributes);

                window.postMessage({
                  type: 'gateway-response-success',
                }, '*');

                commit('setIsSubmitted', false);
              })
              .catch(error => {
                commit('setIsSubmitted', false);
                commit('setCaptchaToken', '');

                const errtk = error?.tk
                  ? error?.translation_key
                  : error?.response?.data.error_translation_key;
                const errmsg = error?.custom
                  ? error?.message
                  : error?.response?.data?.error;
                commit('setError', {
                  title: 'Cardknox Error!',
                  translationKey: [errtk, errmsg],
                });

                logger.logMakeDonationCatchError(error, errtk, errmsg);
              });
          })
        return true;
      }

      case 'authorize': {
        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const { successful, error_message } = response.data.data.attributes;

            if (!successful) throw createError(error_message, response.data.data.attributes);

            window.postMessage({
              type: 'gateway-response-success',
            }, '*');

            commit('setIsSubmitted', false);
          })
          .catch(error => {
            commit('setIsSubmitted', false);
            commit('setCaptchaToken', '');

            const errtk = error?.tk
              ? error?.translation_key
              : error?.response?.data.error_translation_key;
            const errmsg = error?.custom
              ? error?.message
              : error?.response?.data?.error;
            commit('setError', {
              title: 'Authorize Error!',
              translationKey: [errtk, errmsg],
            });

            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }

      // Braintree
      case 'braintree': {
        dispatch('braintreeDropinRequestPaymentMethod').then(({ nonce }: IRequestPaymentMethodPayload) => {
          commit(
            'setDonationIncludedGatewayParams',
            {
              braintree_payment_nonce: nonce,
            },
          );

          const reqData: IDonationRequest = getReqData()

          axios
            .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
            .then(response => {
              const { data } = response;
              const { successful, error_message } = data.data.attributes;

              if (!successful) throw createError(error_message, data.data.attributes);

              window.postMessage({
                type: 'gateway-response-success',
              }, '*');
              commit('setIsSubmitted', false);
            })
            .catch((error: string | any) => {
              commit('setCaptchaToken', '');
              commit('setIsSubmitted', false);
              commit('setOverlay', false);

              const errtk = error?.tk
                ? error?.translation_key
                : error?.response?.data.error_translation_key;
              const errmsg = error?.custom
                ? error?.message
                : error?.response?.data?.error;
              commit('setError', {
                title: 'Braintree Error!',
                translationKey: [errtk, errmsg],
              });

              logger.logMakeDonationCatchError(error, errtk, errmsg);
            });
        })
        return true;
      }

      // Pledger
      case 'pledger': {
        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const { successful, error_message } = response.data.data.attributes;

            if (!successful) throw createError(error_message, response.data.data.attributes);

            commit('setIsSubmitted', false);
            commit('setDialogTitle', 'Pledger');
            commit('setDialogIframeSrc', response.data.data.attributes.parameters.url);
            commit('setDialogVisibleStatus', true);
          })
          .catch(error => {
            commit('setIsSubmitted', false);
            commit('setCaptchaToken', '');

            const errtk = error?.tk
              ? error?.translation_key
              : error?.response?.data.error_translation_key;
            const errmsg = error?.custom
              ? error?.message
              : error?.response?.data?.error;
            commit('setError', {
              title: 'Pledger Error!',
              translationKey: [errtk, errmsg],
            });

            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }

      // Mancal
      case 'mancal':
      case 'mancal-bit': {
        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const { successful, error_message } = response.data.data.attributes;

            if (!successful) throw createError(error_message, response.data.data.attributes);

            const { redirect_url } = response.data.data.attributes.parameters
            const { params }: { params: object } = response.data.data.attributes.parameters

            const form = document.createElement('form');
            form.action = redirect_url;
            form.method = 'post';
            form.target = 'donationsIframe'
            form.append(...Object.entries(params).map(([key, val]) => {
              const input = document.createElement('input');
              input.type = 'hidden';
              input.name = key
              input.value = val;
              return input;
            }))

            document.body.append(form);

            window.addEventListener('donationsIframeLoaded', () => {
              form.submit()
            })

            commit('setIsSubmitted', false);
            commit('setDialogTitle', 'Mancal');
            commit('setDialogIframeSrc', '');
            commit('setDialogVisibleStatus', true);
          })
          .catch(error => {
            commit('setIsSubmitted', false);
            commit('setCaptchaToken', '');

            const errtk = error?.tk
              ? error?.translation_key
              : error?.response?.data.error_translation_key;
            const errmsg = error?.custom
              ? error?.message
              : error?.response?.data?.error;
            commit('setError', {
              title: 'Mancal Error!',
              translationKey: [errtk, errmsg],
            });

            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }

      case 'usaepay': {
        const reqData: IDonationRequest = getReqData()

        dispatch('handleUsaepaySubmit').then(payment_key => {
          commit(
            'setDonationIncludedGatewayParams',
            { usaepay_payment_key: payment_key },
          );

          axios
            .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
            .then(response => {
              const { data } = response;
              const { successful, error_message } = data.data.attributes;

              if (!successful) throw createError(error_message, data.data.attributes);

              window.postMessage({
                type: 'gateway-response-success',
              }, '*');
              commit('setIsSubmitted', false);
            })
            .catch((error: string | any) => {
              commit('setCaptchaToken', '');
              commit('setIsSubmitted', false);
              commit('setOverlay', false);

              const errtk = error?.tk
                ? error?.translation_key
                : error?.response?.data.error_translation_key;
              const errmsg = error?.custom
                ? error?.message
                : error?.response?.data?.error;
              commit('setError', {
                title: 'Usaepay Error!',
                translationKey: [errtk, errmsg],
              });

              logger.logMakeDonationCatchError(error, errtk, errmsg);
            });
        })
          .catch((error: string | any) => {
            commit('setCaptchaToken', '');
            commit('setIsSubmitted', false);
            commit('setOverlay', false);

            commit('setError', {
              title: 'Usaepay Error!',
              text: error,
            });
          });
        return true;
      }

      case 'banquest':
      case 'banquest-ach': {
        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const { successful, error_message } = response.data.data.attributes;

            if (!successful) throw createError(error_message, response.data.data.attributes);

            window.postMessage({
              type: 'gateway-response-success',
            }, '*');

            commit('setIsSubmitted', false);
          })
          .catch(error => {
            commit('setIsSubmitted', false);
            commit('setCaptchaToken', '');

            const [errtk, errmsg] = parseError(error, state.paymentMethod)

            commit('setError', {
              title: 'Gateway Error!',
              translationKey: [errtk, errmsg],
            });

            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }

      case 'stripe-element': {
        commit('setStripeIdempotencyKey', nanoid());

        commit('setDonationIncludedGatewayParams', {
          stripe_idempotency_key: rootState.stripe.stripeIdempotencyKey,
        });

        const reqData: IDonationRequest = getReqData()

        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const { data } = response;
            const { successful, error_message } = data.data.attributes;

            if (!successful) throw createError(error_message, data.data.attributes);

            commit('setStripeIdempotencyKey', nanoid());

            const p = data.data.attributes?.parameters || {};
            commit('setPaymentIntentClientSecret', p.payment_intent_client_secret || '');

            commit('setIsSubmitted', false);
            commit('setDialogTitle', 'Stripe');
            commit('setDialogIframeSrc', '');
            commit('setDialogVisibleStatus', true);
          })
          .catch((error: string | any) => {
            commit('setStripeIdempotencyKey', nanoid());
            commit('setCaptchaToken', '');
            commit('setIsSubmitted', false);
            commit('setOverlay', false);

            const errtk = error?.tk
              ? error?.translation_key
              : error?.response?.data.error_translation_key;
            const errmsg = error?.custom
              ? error?.message
              : error?.response?.data?.error;
            commit('setError', {
              title: 'Gateway Error!',
              translationKey: [errtk, errmsg],
            });
            logger.logMakeDonationCatchError(error, errtk, errmsg);
          })
        return true;
      }

      case 'stripe-terminal': {
        commit('setStripeIdempotencyKey', nanoid());

        commit('setDonationIncludedGatewayParams', {
          stripe_idempotency_key: rootState.stripe.stripeIdempotencyKey,
        });

        const reqData: IDonationRequest = getReqData()

        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const { data } = response;
            const { successful, error_message } = data.data.attributes;

            if (!successful) throw createError(error_message, data.data.attributes);

            commit('setStripeIdempotencyKey', nanoid());

            const p = data.data.attributes?.parameters || {};
            commit('setPaymentIntentClientSecret', p.payment_intent_client_secret || '');

            commit('setIsSubmitted', false);
            commit('setDialogTitle', 'Stripe');
            commit('setDialogIframeSrc', '');
            commit('setDialogVisibleStatus', true);
          })
          .catch((error: string | any) => {
            commit('setStripeIdempotencyKey', nanoid());
            commit('setCaptchaToken', '');
            commit('setIsSubmitted', false);
            commit('setOverlay', false);

            const errtk = error?.tk
              ? error?.translation_key
              : error?.response?.data.error_translation_key;
            const errmsg = error?.custom
              ? error?.message
              : error?.response?.data?.error;
            commit('setError', {
              title: 'Gateway Error!',
              translationKey: [errtk, errmsg],
            });
            logger.logMakeDonationCatchError(error, errtk, errmsg);
          })
        return true;
      }

      // Gama
      case 'gama':
      case 'gama-bit': {
        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const { successful, error_message } = response.data.data.attributes;

            if (!successful) throw createError(error_message, response.data.data.attributes);

            const { sessionId, base_url } = response.data.data.attributes.parameters

            const gamapayScript = useExternalScript(`${base_url}/dist/gamapay-bundle.js`)

            gamapayScript?.onLoad(() => {
              window.gamapayInit(sessionId)
            })

            gamapayScript?.load()

            commit('setIsSubmitted', false);
          })
          .catch(error => {
            commit('setIsSubmitted', false);
            commit('setCaptchaToken', '');

            const errtk = error?.tk
              ? error?.translation_key
              : error?.response?.data.error_translation_key;
            const errmsg = error?.custom
              ? error?.message
              : error?.response?.data?.error;
            commit('setError', {
              title: 'Gama Error!',
              translationKey: [errtk, errmsg],
            });

            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }

      // Peach
      case 'peach':
      case 'peach-bit': {
        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const { successful, error_message } = response.data.data.attributes;

            if (!successful) throw createError(error_message, response.data.data.attributes);

            commit('setIsSubmitted', false);
            commit('setDialogTitle', 'Peach');
            commit('setDialogIframeSrc', response.data.data.attributes.parameters.url);
            commit('setDialogVisibleStatus', true);
          })
          .catch(error => {
            commit('setIsSubmitted', false);
            commit('setCaptchaToken', '');

            const errtk = error?.tk
              ? error?.translation_key
              : error?.response?.data.error_translation_key;
            const errmsg = error?.custom
              ? error?.message
              : error?.response?.data?.error;
            commit('setError', {
              title: 'Peach Error!',
              translationKey: [errtk, errmsg],
            });

            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }

      // Powerdoc Direct Debit
      case 'powerdoc-direct-debit': {
        axios
          .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
          .then(response => {
            const { successful, error_message } = response.data.data.attributes;

            if (!successful) throw createError(error_message, response.data.data.attributes);

            commit('setIsSubmitted', false);
            commit('setDialogTitle', 'Powerdoc Direct Debit');
            commit('setDialogIframeSrc', response.data.data.attributes.parameters.url);
            commit('setDialogVisibleStatus', true);
          })
          .catch(error => {
            commit('setIsSubmitted', false);
            commit('setCaptchaToken', '');

            const errtk = error?.tk
              ? error?.translation_key
              : error?.response?.data.error_translation_key;
            const errmsg = error?.custom
              ? error?.message
              : error?.response?.data?.error;
            commit('setError', {
              title: 'Powerdoc Direct Debit Error!',
              translationKey: [errtk, errmsg],
            });

            logger.logMakeDonationCatchError(error, errtk, errmsg);
          });
        return true;
      }

      case 'sumit': {
        const submitCallBack = (r: any) => {
          const e: HTMLElement | null = document.getElementById('og-token');
          e?.parentNode?.removeChild(e)

          if (r?.Status !== 0 || !r?.Data?.SingleUseToken) {
            commit('setError', {
              title: 'Sumit Error!',
              text: r?.UserErrorMessage || '',
            });
            commit('setIsSubmitted', false);

            return false
          }

          commit(
            'setDonationIncludedGatewayParams',
            { sumit_payment_key: r.Data.SingleUseToken },
          );

          axios
            .post(urls.campaignParams.replace(':campaignId', campaignId), reqData, config)
            .then(response => {
              const { data } = response;
              const { successful, error_message } = data.data.attributes;

              if (!successful) throw createError(error_message, data.data.attributes);

              window.postMessage({
                type: 'gateway-response-success',
              }, '*');
              commit('setIsSubmitted', false);
            })
            .catch((error: string | any) => {
              commit('setCaptchaToken', '');
              commit('setIsSubmitted', false);
              commit('setOverlay', false);

              const errtk = error?.tk
                ? error?.translation_key
                : error?.response?.data.error_translation_key;
              const errmsg = error?.custom
                ? error?.message
                : error?.response?.data?.error;
              commit('setError', {
                title: 'Sumit Error!',
                translationKey: [errtk, errmsg],
              });

              logger.logMakeDonationCatchError(error, errtk, errmsg);
            });

          return true
        }

        window.OfficeGuy.Payments.CreateToken({
          CompanyID: rootState.donation.paymentConfig.attributes.configuration.sumit_company_id,
          APIPublicKey: rootState.donation.paymentConfig.attributes.configuration.sumit_public_key,
          ResponseCallback: submitCallBack,
        });

        return true;
      }

      default:
        commit('setIsSubmitted', false);
        commit('setError', {
          title: 'Donation Failed!',
          text: 'There is no payment method!',
        });
        return false;
    }
  },

  addError({ commit }, error) {
    commit('setError', error);
  },

  clearError({ commit }) {
    commit('setError', null);
  },

  returnToCampaign({ state, commit, rootState }) {
    let owerwriteLink = getOverwriteReturnToWithVars(rootState);
    if (owerwriteLink && state.donationParams.data.attributes.config?.overwrite_return_to_linker) {
      owerwriteLink = addGALinker(owerwriteLink);
    }
    const inIframe = window.self !== window.parent;

    window.parent.postMessage({ type: 'return-to-campaign' }, '*');

    commit('setDefaultDonationData');

    if (!owerwriteLink) {
      commit('setStepFlag', 1);
    }

    if (inIframe && owerwriteLink) {
      window.parent.location.href = owerwriteLink;
    }

    if (!inIframe) {
      window.location.href = owerwriteLink || `https://charidy.com/${state.campaign.data.attributes.short_link}`;
    }
  },

  setTipByPercentage({ state, commit }, percentage) {
    const amount = Math.round(state.originAmount * (percentage / 100) * 10) / 10
    commit('setTip', amount);
  },

  async getExchangeRate({ state }, amount: number = 1): Promise<number> {
    const params = {
      amount,
      from: state.campaign.data.attributes.currency.toLowerCase(),
      to: state.donationData.attributes.currency.toLowerCase(),
    };

    const { data: { data } } = await axios.get<IXchangeResponse>(urls.getExchange, { params });
    const { attributes: attr } = data;

    return attr.amount_out;
  },

};

// getters
const getters: GetterTree<Donation, RootState> = {
  getFullBillingName(state) {
    const attr = state.donationData.attributes
    const { billing_first_name: bfn, billing_last_name: bln, receipt_name: rn } = attr;

    return (rn || attr.billing_name || `${bfn} ${bln}`).replace(/[`'"]+/g, '');
  },

  getGatewayList(state) {
    return state.included.reduce((res, el) => {
      res[el.id] = el;
      return res;
    }, {} as { [key: string]: IIncluded })
  },

  getGatewayListByTeam(state, getters, rootState) {
    return getters.getGatewayListByCurrency.filter((gateway: IIncluded) => {
      let fgi = rootState.gateways.force_gateway_id;
      if (fgi.includes(',')) {
        [fgi] = fgi.split(',');
      }
      let fgis: string[] = [];
      if (rootState.gateways.force_gateway_id.includes(',')) {
        fgis = rootState.gateways.force_gateway_id.split(',');
      }
      const lg = rootState.gateways.lock_gateway && Boolean(getters.getGatewayList[fgi]);

      if (fgis.length > 0 && rootState.gateways.lock_gateway) {
        if (fgis.includes(gateway.id)) {
          return true;
        }

        return false;
      }

      if (fgi && lg && gateway.id === fgi) {
        return true;
      }
      if (fgi && lg) {
        return false;
      }

      return false;
    })
  },

  getGatewayListByCurrency(state, getters, rootState) {
    return state.included
      .filter(gateway => {
        if (!allowedList.includes(gateway.attributes.name)) return false;

        const { recurring, installment, installment_period } = state.donationData.attributes;

        let gatewaysList: string[] = [];
        const donationCurrency = state.donationData.attributes.currency.toLowerCase();
        const currencyList = gateway.attributes
          .available_currency_list?.map(el => el.code.toLowerCase());

        if (recurring) {
          gatewaysList = state.donationParams.data.attributes.config.recurring_config.gateways || []
        }
        if (installment) {
          gatewaysList = gatewaysList.concat(
            state.donationParams.data.attributes.config.installment_config.gateways || [],
          )
          if (installment_period > 1) {
            gatewaysList.push('hk-bank-transfer');
          }
        } else if (gateway.attributes.name === 'hk-bank-transfer') { // show hk-bank-transfer only if installment_period > 1
          return false;
        }

        switch (gateway.attributes.name) {
          case 'stripe-google-pay':
          case 'stripe-apple-pay': {
            if (
              gatewaysList.length
              && !gatewaysList.includes(gateway.attributes.name)
            ) {
              return false
            }
            const isApplePay = !!rootState.stripe.canMakePayment?.applePay
            if (isApplePay) {
              const { verified_domains } = gateway.attributes.configuration;
              const { host } = window.location
              if (
                !(verified_domains.includes(host) || verified_domains === 'all')
              ) {
                return false
              }
            }
            if (!rootState.stripe.canMakePayment) {
              return false
            }
            break;
          }

          default:
            break;
        }

        if (gatewaysList.length) {
          return gatewaysList.indexOf(gateway.attributes.name) !== -1
            && currencyList
            && currencyList.length
            && currencyList.indexOf(donationCurrency) !== -1;
        }

        if (currencyList && currencyList.length) {
          return currencyList.indexOf(donationCurrency) !== -1;
        }

        return true;
      })
      .sort((a, b) => {
        const aOrder = a.attributes.order || 1;
        const bOrder = b.attributes.order || 1;
        return aOrder - bOrder;
      })
  },

  showRecurringOption(state, getters): boolean {
    const { recurring_config } = state.donationParams.data.attributes.config
    const gln: any[] = getters.getGatewayListByCurrency
      .map((gateway: any) => gateway.attributes.name);
    let rgln: any[] = [];

    if (recurring_config.available) {
      rgln = recurring_config.gateways
    }

    if (state.paymentMethod && !rgln.includes(state.paymentMethod)) {
      return false;
    }

    return gln.some(i => rgln.includes(i));
  },

  showInstallmentOption(state, getters): boolean {
    const { installment_config } = state.donationParams.data.attributes.config
    const gln: any[] = getters.getGatewayListByCurrency
      .map((gateway: any) => gateway.attributes.name);
    let rgln: any[] = [];

    if (installment_config.available) {
      rgln = installment_config.gateways
    }

    if (state.paymentMethod && !rgln.includes(state.paymentMethod)) {
      return false;
    }

    const hideForGateway = ['dlocal']
    if (hideForGateway.includes(state.paymentMethod)) {
      return false
    }

    return gln.some(i => rgln.includes(i));
  },

  getStreamId: () => state.donationData.attributes.stream_id,

  showAmountInLocCurrency(state) {
    const attr = state.donationParams.data.attributes;
    const { ip_location_default_currency } = attr;
    const { value } = attr.config.show_amount_in_loc_currency_config;
    if (value && ip_location_default_currency) {
      return ip_location_default_currency;
    }

    return false;
  },

  selectedTeams(state) {
    return state.donationIncluded.filter(
      v => v.type === 'donate_to_multiple_teams',
    );
  },

  getMinTeamAmount() {
    return (teamData: any): number => {
      const selectedCode = state.donationData.attributes.currency.toUpperCase();
      const campaignCurrencyCode = state.campaign.data.attributes.currency.toUpperCase();
      const config = state.donationParams.data.attributes.config.min_donation_amount_for_teams;
      const { changeRate } = state;

      if (teamData.team && config?.[teamData.team.id]) {
        const minDonationAmount = config[teamData.team.id]

        if (minDonationAmount[selectedCode]) {
          return minDonationAmount[selectedCode];
        }

        if (changeRate && minDonationAmount[campaignCurrencyCode] >= 1) {
          return Math.ceil(
            (minDonationAmount[campaignCurrencyCode]) / changeRate,
          );
        }
      }

      return 0
    }
  },

  totalMinTeamsAmount(state, getters) {
    return Object.keys(state.donationParams.data.attributes.config.min_donation_amount_for_teams)
      .reduce(
        (sum, id) => {
          let s = sum
          const data = {
            team: {
              id: Number(id),
            },
          }
          s += getters.getMinTeamAmount(data)
          return s
        },
        0,
      )
  },

  selectedTeamsTotalAmount(state, getters) {
    const attrs = state.donationData.attributes;
    const startAmount = attrs.donate_to_all_teams ? Math.ceil(state.teamsTotal / (state.changeRate || 1)) * 100 : 0;
    const teams = getters.selectedTeams as IDonationIncluded[];

    let amount = startAmount;
    teams.forEach(v => { amount += +(v.attributes.amount || 0) });

    return amount;
  },

  currencySign: state => state.donationData.attributes.currency_sign,

  originAmount: state => state.originAmount,

  totalAmount: state => state.totalAmount,

  tipAmount: state => state.donationData.attributes.tip_amount,

  donationAmount: state => state.donationData.attributes.amount,

  paymentMethod: state => state.paymentMethod,

  installmentLabel: (state, getters) => (months: number): string => {
    const amt = (Math.round((state.originAmount / months) * 10) / 10).toLocaleString();
    return (months > 1
      ? `${getters.currencySign}${amt} x ${months} ${Vue.prototype.$t('donation.monthly_payments')}`
      : Vue.prototype.$t('donation.single_payment') as string)
  },

  installmentPeriod: state => state.donationData.attributes.installment_period,

  recurringPeriod: state => state.donationData.attributes.recurring_period,

  recurringLabel: (state, getters) => (months: number): string => {
    const $t_monthly_payments = Vue.prototype.$t('donation.monthly_payments');
    const amt = (getters.originAmount * months).toLocaleString();
    return (months > 1
      ? `${getters.originAmount.toLocaleString()}
      x ${months} ${$t_monthly_payments} (${getters.currencySign}${amt})`
      : Vue.prototype.$t('donation.single_payment') as string)
  },

  paymentsLabel: (state, getters): string => {
    if (getters.installmentPeriod > 1) {
      return getters.installmentLabel(getters.installmentPeriod);
    }
    if (getters.recurringPeriod > 1) {
      return getters.recurringLabel(getters.recurringPeriod);
    }
    return Vue.prototype.$t('donation.single_payment') as string;
  },

  paymentsSummaryLabel: (state, getters): string => {
    if (getters.installmentPeriod > 1) {
      return getters.installmentLabel(getters.installmentPeriod);
    }
    if (getters.recurringPeriod > 1) {
      /* Todo: translate this string */
      return Vue.prototype.$t('confirmation.1_of_payments', { recurringPeriod: getters.recurringPeriod }) as string;
    }
    return Vue.prototype.$t('donation.single_payment') as string;
  },

  multiplier: state => state.donationParams.data.attributes.current_multiplier,

  multipliedAmount: (state, getters): number => Math.ceil(getters.originAmount
    * (getters.recurringPeriod || 1)
    * getters.multiplier),

  totalRecurringAmount: (state, getters): number => getters.originAmount
    * (getters.recurringPeriod || 1) + getters.tipAmount,

  orgName: state => state.donationParams.data.attributes.config.organization.orgname,

  feeConfig: state => state.donationParams.data.attributes.config.include_payment_system_fee_config,

  tipOptions: (state): number[] => {
    const { options, thresholds } = state.donationParams.data.attributes.config.tip_config;
    const tp = thresholds?.map(el => el.percent) || [];

    return (options?.length > 0
      ? [...new Set([0, ...options, ...tp])]
      : [...new Set([0, 5, 10, 15, 20, ...tp])]).sort((a, b) => a - b);
  },

  tipOnConfirmStep: (state, getters): boolean => getters.feeConfig.show_on_confirmation_step,

  stepFlag: (state): number => state.stepFlag,

  uiMode: (state): string => state.donationParams.data?.attributes.ui_mode,

  recurringConfig: (state): RecurringConfig => state.donationParams.data.attributes
    .config.recurring_config,

  multiplierOnDay: (state, getters) => {
    const { available, period } = getters.recurringConfig as RecurringConfig;
    if (available && period === 'day') {
      return 30;
    }

    return 1;
  },

  teamToGateway: (state: Donation) => {
    const config = state.donationParams.data.attributes.config.team_to_gateway_config;

    if (config?.team_gateway) {
      return config.team_gateway;
    }

    return null;
  },

  teamFilterGateway: (state: Donation) => {
    const config = state.donationParams.data.attributes.config.team_to_gateway_config;

    if (config?.team_filter_gateway) {
      return config.team_filter_gateway;
    }

    return null;
  },

  useParentTeamGatewayForChildTeam(): boolean | undefined {
    const config = state.donationParams.data.attributes.config.team_to_gateway_config
    return config?.use_parent_team_gateway_for_child_team;
  },

  useGrandParentTeamGatewayForChildTeam(): boolean | undefined {
    const config = state.donationParams.data.attributes.config.team_to_gateway_config
    return config?.use_grand_parent_team_gateway_for_child_team;
  },

  gatewayToTeam(_, getters) {
    const ttg: ITeamToGatewayConfig | null = getters.teamToGateway;

    if (ttg) {
      return Object.fromEntries(
        Object.entries(ttg).map(([k, v]) => [v, k]),
      );
    }

    return null;
  },

  isMeshulamBitDisabled(state) {
    // const isAndroid = getPlatform() === 'android';

    if (
      isFBApp()
      && (state?.paymentConfig?.attributes?.name === 'meshulam-bit'
        || state?.paymentConfig?.attributes?.name === 'meshulam-v2-bit')
    ) {
      return true;
    }

    return false;
  },

  isMeshulamGooglePayDisabled(state) {
    const isAndroid = getPlatform() === 'android';

    if ((!isAndroid || !isChrome()) && state?.paymentConfig?.attributes?.name === 'meshulam-google-pay') {
      return true;
    }

    return false;
  },

  isGatewayDisabled(state) {
    const isWeb = getPlatform() === 'web';
    const config = state.donationParams.data.attributes.config.disable_gateway_on_palatform_config

    if (isWeb && config?.web?.includes(state.paymentMethod)) {
      return true;
    }

    return false;
  },

  confirmationStep1(state) {
    if (state.paymentMethod === 'stripe-terminal') {
      return true;
    }
    return state.donationParams.data.attributes.config.confirmation_step_1;
  },

  hideAmountInput(state) {
    const pm = state.paymentMethod
    const { mode } = state.paymentConfig?.attributes?.configuration || {}
    const { gateway_mode_config } = state.donationParams?.data?.attributes?.config || {}

    if (pm === 'yaadpay' && mode === '1') {
      return {
        setAmount: 1,
      }
    }

    if (
      pm === 'yaadpay'
      && gateway_mode_config?.value
      && gateway_mode_config?.gateway_mode?.[pm] === '1'
    ) {
      return {
        setAmount: 1,
      }
    }

    return false;
  },

  taxCalcToolData(state) {
    const amount = state.donationData.attributes.amount / 100
    const { value, options } = state.donationParams.data.attributes.config.tax_calc_tool || {}

    if (value && options?.length && amount) {
      const calcTax = (amount: number, tax_percent: number) => Math.round(amount * (tax_percent / 100))

      const res = options.reduce(
        (res, o) => {
          const amtLoc = amount - o.condition_amount;

          switch (o.condition) {
            case 'above':
              if (amtLoc > 0) {
                res.amounts.push(amtLoc);
                res.percentages.push(o.tax_percent);

                const tax = calcTax(amtLoc, o.tax_percent)

                res.taxes.push(tax);
                res.taxesSum += tax
              }
              return res

            case 'above_equal':
              if (amtLoc >= 0) {
                res.amounts.push(amtLoc);
                res.percentages.push(o.tax_percent);

                const tax = calcTax(amtLoc, o.tax_percent)

                res.taxes.push(tax);
                res.taxesSum += tax
              }
              return res

            case 'below':
              if (amtLoc < 0) {
                res.amounts.push(amount);
                res.percentages.push(o.tax_percent);

                const tax = calcTax(amount, o.tax_percent)

                res.taxes.push(tax);
                res.taxesSum += tax
              } else {
                res.amounts.push(o.condition_amount);
                res.percentages.push(o.tax_percent);

                const tax = calcTax(o.condition_amount, o.tax_percent)

                res.taxes.push(tax);
                res.taxesSum += tax
              }
              return res

            case 'below_equal':
              if (amtLoc <= 0) {
                res.amounts.push(amount);
                res.percentages.push(o.tax_percent);

                const tax = calcTax(amount, o.tax_percent)

                res.taxes.push(tax);
                res.taxesSum += tax
              } else {
                res.amounts.push(o.condition_amount);
                res.percentages.push(o.tax_percent);

                const tax = calcTax(o.condition_amount, o.tax_percent)

                res.taxes.push(tax);
                res.taxesSum += tax
              }
              return res

            default:
              return res
          }
        },
        {
          amounts: [] as number[],
          percentages: [] as number[],
          taxes: [] as number[],
          taxesSum: 0,
          aftertax: 0,
        },
      )

      res.aftertax = amount - res.taxesSum;

      return res
    }

    return false;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
