
import Vue from 'vue';
import axios from 'axios';
import { mapGetters } from 'vuex';
import customMapState from '@/helpers/mapHelper';

import { get } from '@/helpers/getUrlParameters';
import { getNumber } from '@/helpers/validator';
import IconMultipliedX from '@/components/shared/icons/icon-multiplied-x.vue';
import urls from '../../constants/urls';
import { IRootState } from '../../models/IRootState';
import { AmountConfig, AvailableCurrencyList } from '../../models/IDonationParams';
import { IXchangeResponse } from './subcomponents-models/DonationAmount-models';
import { AvailableDisplayCurrencyList } from '../../store/modules/models/donation'

import TipBlock from './TipBlock.vue';
import PanelDonationDailyInfo from './PanelDonationDailyInfo.vue';
import WarningMessageByAmount from './WarningMessageByAmount.vue';

const installmentTmForGateways = [
  'yaadpay',
  'israeltoremet',
  'meshulam',
  'meshulam-v2',
  'nedarim-plus-native',
  'tranzila',
  'pelecard',
  'cardcom',
  'jaffa',
  'icredit-rivhit',
  'aminut',
  'kehilot',
  'mancal',
  'gama',
];

const VueComponent = Vue.extend({
  components: {
    TipBlock,
    PanelDonationDailyInfo,
    IconMultipliedX,
    WarningMessageByAmount,
  },

  data() {
    return {
      installmentTmOption: false,
      lockPresetInstallmentTmOption: false,
      paymentSchedule: 'onetime',
      companyFinished: false,
      minAmountChangeRate: {} as Record<string, number>,
      minAmountChangeRateLoading: false,
    };
  },

  computed: {
    amount: {
      get(): string {
        return this.originAmount ? String(this.originAmount) : '';
      },
      set(value: string) {
        this.$store.commit('setOriginAmount', value);
      },
    },

    hideAmount: {
      get() {
        const state = this.$store.state as IRootState;
        return state.donation.donationData.attributes.hide_donation_amount;
      },
      set(value: boolean) {
        this.$store.commit('setHideAmount', value);
      },
    },

    zeroRecurringModel: {
      get(): boolean {
        const state = this.$store.state as IRootState;
        return state.donation.donationData.attributes.zero_recurring;
      },
      set(value: boolean) {
        this.$store.commit('setZeroRecurring', value);
        if (value) {
          this.recurringStatus = false
        }
      },
    },

    recurringStatus: {
      get(): boolean {
        return this.$store.state.donation.donationData.attributes.recurring;
      },
      set(status: boolean) {
        this.$store.commit(
          'setRecurringPeriod',
          status ? this.recurringConfig.duration : 0,
        );
        if (this.installmentPeriod) {
          this.$store.commit('setInstallmentPeriod', 0);
          this.$store.commit('setInstallmentStatus', false);
        }
      },
    },

    selectedRecurringPeriod: {
      get(): number {
        return this.$store.state.donation.donationData.attributes
          .recurring_period;
      },
      set(period: number) {
        if (this.selectedRecurringPeriod !== period) {
          this.$store.commit('setRecurringPeriod', period);
          if (this.installmentPeriod) {
            this.$store.commit('setInstallmentPeriod', 1);
            this.$store.commit('setInstallmentStatus', false);
          }
        }
      },
    },

    installmentPeriod: {
      get(): number {
        return this.$store.state.donation.donationData.attributes
          .installment_period;
      },
      set(period: number) {
        if (this.installmentPeriod !== period) {
          const maxPeriod = this.installmentMaxTmOptionMonths;
          if (period > maxPeriod) this.installmentTmOption = false;

          this.$store.commit('setInstallmentPeriod', period);
          this.$store.commit('setInstallmentStatus', period > 1);
          if (period > 1 && this.recurringStatus) {
            this.$store.commit('setRecurringPeriod', 0);
          }
        }
      },
    },

    paymentFeeStatus: {
      get(): boolean {
        return this.$store.state.donation.donationData.attributes
          .include_payment_system_fee;
      },
      set(status: boolean) {
        this.$store.commit('setIncludePaymentFeeStatus', status);
        if (status) {
          this.$store.commit('setIncludePaymentFeeAmount', this.getFeeAmount() * 100);
        } else {
          this.$store.commit('setIncludePaymentFeeAmount', 0);
        }
      },
    },

    recurringUiMode(): string {
      const { ui_mode, available } = this.recurringConfig;

      if (available && ui_mode === undefined) {
        this.$store.dispatch('addError', {
          title: 'Recurring Config',
          text: 'Parameter ui_mode not exist',
        });
      }

      return ui_mode || 'checkbox';
    },

    installmentAvailable(): boolean {
      const { available, currencies } = this.installmentConfig;

      if (available && this.showInstallmentOption) {
        if (currencies && currencies.length) {
          return currencies.includes(this.donationCurrency.code);
        }
        return true;
      }

      return false;
    },

    showInstallmentTmByGateway(): boolean {
      return installmentTmForGateways.includes(this.paymentMethod);
    },

    installmentUiMode(): string {
      const { ui_mode, available } = this.installmentConfig;

      if (available && ui_mode === undefined) {
        this.$store.dispatch('addError', {
          title: 'Installment Config',
          text: 'Parameter ui_mode not exist',
        });
      }

      return ui_mode || 'ddl_months_split_charge';
    },

    limitedCurrencies(): boolean {
      const { currencies } = this.recurringConfig;
      return (
        currencies?.length === 0
        || currencies === null
        || currencies.indexOf(
          this.donationCurrency.code.toLowerCase(),
        ) !== -1
      );
    },

    showFeeCheckbox(): boolean {
      const { feeConfig, paymentMethod, donationAmount } = this;

      return feeConfig?.available
        && feeConfig?.fee_rate?.[paymentMethod]
        && !feeConfig.show_on_confirmation_step
        && donationAmount > 100
    },

    feeAmount(): number {
      return this.getFeeAmount();
    },

    matcherInfoTotal(): number {
      const {
        totalAmount, selectedRecurringPeriod, multiplier,
      } = this;

      return (totalAmount * (selectedRecurringPeriod || 1) * multiplier)
    },

    recurringLabel(): string {
      const { period } = this.recurringConfig;

      if (period === 'day') {
        return this.$t(
          'donation.recurring_label_daily',
          'Keep my daily donation of {amount} for next',
          {
            amount: `${this.donationCurrency.sign}${this.originAmount}`,
          },
        ) as string;
      }

      return this.$t('donation.recurring_label') as string;
    },

    campaignCurrency(): AvailableDisplayCurrencyList {
      const state = this.$store.state as IRootState;
      if (this.showAmountInLocCurrency) {
        return this.showAmountInLocCurrency;
      }
      return {
        sign: state.donation.campaign.data.attributes.currency_sign,
        code: state.donation.campaign.data.attributes.currency,
      }
    },

    currencyList(): AvailableCurrencyList[] {
      if (this.lockGateway) {
        return this.gatewayCurrencyList || [];
      }
      return this.allCurrencyList;
    },

    ...mapGetters({
      multiplierOnDay: 'multiplierOnDay',
      showRecurringOption: 'showRecurringOption',
      showInstallmentOption: 'showInstallmentOption',
      showAmountInLocCurrency: 'showAmountInLocCurrency',
      selectedTeamsTotalAmount: 'selectedTeamsTotalAmount',
      taxCalcToolData: 'taxCalcToolData',
      recurringDuration: 'recurringDuration',
      zeroRecurringActive: 'zeroRecurringActive',
      installmentAvailablePeriods: 'installmentAvailablePeriods',
      installmentMaxTmOptionMonths: 'installmentMaxTmOptionMonths',
      orgName: 'orgNameByLang',
    }),

    ...customMapState({
      isLoadCampaignParams: (state: IRootState) => state.donation.isLoadCampaignParams,

      allCurrencyList: (state: IRootState) => state.donation.donationParams.data
        .attributes.available_currency_list.sort((a, b) => a.code.localeCompare(b.code)),

      gatewayCurrencyList: (state: IRootState) => state.donation.paymentConfig
        .attributes?.available_currency_list?.sort((a, b) => a.code.localeCompare(b.code)),

      donationAmount: (state: IRootState) => state.donation.donationData.attributes.amount,

      totalAmount: (state: IRootState) => state.donation.totalAmount,

      changeRate: (state: IRootState) => state.donation.changeRate,

      donationCurrency: (state: IRootState) => ({
        code: state.donation.donationData.attributes.currency,
        sign: state.donation.donationData.attributes.currency_sign,
      }),

      secondaryColor: (state: IRootState) => state.donation.donationParams.data
        .attributes.config.style_config.preset_colors.secondary || '#ca6d4f',

      config: (state: IRootState) => state.donation.donationParams.data.attributes.config,

      donationParamsAttrs: (state: IRootState) => state.donation.donationParams.data.attributes,

      paymentMethod: (state: IRootState) => state.donation.paymentMethod,

      paymentConfig: (state: IRootState) => state.donation.paymentConfig,

      exchangeValue: (state: IRootState) => state.donation.exchangeValue,

      multiplier: (state: IRootState) => state.donation.donationParams.data
        .attributes.current_multiplier,

      feeConfig: (state: IRootState) => state.donation.donationParams.data.attributes.config
        .include_payment_system_fee_config,

      recurringConfig: (state: IRootState) => state.donation.donationParams.data.attributes
        .config.recurring_config,

      installmentConfig: (state: IRootState) => state.donation.donationParams.data.attributes
        .config.installment_config,

      customStyle: (state: IRootState): string => {
        const customStyle = state.donation.donationParams.data.attributes.config.style_config
          .custom_style;

        if (customStyle.length > 0) {
          return customStyle;
        }

        return '';
      },

      customStyleList: (state: IRootState) => state.donation.donationParams.data.attributes.config.style_config
        .custom_style_list,

      customScript: (state: IRootState): string => {
        if (!state.donation.isLoadCampaignParams) return '';
        const customScript = state.donation.donationParams.data.attributes.config.style_config
          .custom_script;

        if (customScript && customScript.length > 0) {
          return customScript;
        }

        return '';
      },

      originAmount: (state: IRootState) => state.donation.originAmount,

      amountRestricted: (state: IRootState) => {
        const { config } = state.donation.donationParams.data.attributes
        const { readonly } = state.donation;
        const {
          value,
          amount_restricted_to_item_price,
        } = config.goal_as_items;
        const { readonly: amtConfReadonly } = config?.amount_config || {}

        return (value && amount_restricted_to_item_price)
          || readonly
          || amtConfReadonly;
      },
    }),

    paymentMethodWithId(): string {
      return `${this.paymentMethod}_${this.paymentConfig?.id}`
    },

    amountConfig(): AmountConfig {
      return this.config.amount_config;
    },

    showOptionHideAmount(): boolean {
      return this.config.option_hide_donation_amount;
    },

    minFormAmount(): number {
      return this.donationParamsAttrs.minimal_donation_amount;
    },

    minFormAmountCurrencyCode(): string {
      return this.donationParamsAttrs.minimal_donation_amount_currency.code.toUpperCase();
    },

    minAmount(): number {
      const selectedCode = this.donationCurrency.code.toUpperCase();
      const campaignCurrencyCode = this.campaignCurrency.code.toUpperCase();
      const minDonationAmount = this.config.minimum_donation_amount;

      if (this.recurringStatus && this.recurringConfig.min_amount) {
        if (this.changeRate) {
          return Math.ceil(
            this.recurringConfig.min_amount / this.changeRate,
          );
        }

        return this.recurringConfig.min_amount;
      }

      if (this.installmentPeriod > 1 && this.installmentConfig.min_amount) {
        if (this.changeRate) {
          return Math.ceil(
            this.installmentConfig.min_amount / this.changeRate,
          );
        }

        return this.installmentConfig.min_amount;
      }

      if (minDonationAmount[selectedCode]) {
        return minDonationAmount[selectedCode];
      }

      if (this.changeRate) {
        return Math.ceil(
          (minDonationAmount[campaignCurrencyCode] || 1) / this.changeRate,
        );
      }

      if (this.minFormAmountCurrencyCode !== selectedCode && this.minFormAmount && this.minAmountChangeRateLoading) {
        const rate = this.minAmountChangeRate[selectedCode] || 1

        return Math.ceil(this.minFormAmount * rate);
      }

      return 1;
    },

    maxAmount(): number | boolean {
      const selectedCode = this.donationCurrency.code.toUpperCase();
      const campaignCurrencyCode = this.campaignCurrency.code.toUpperCase();
      const maxDonationAmount = this.config.maximum_donation_amount;

      const attrs = this.paymentConfig?.attributes;
      if (this.installmentPeriod > 1) {
        return attrs?.installment_max_amount || attrs?.max_amount || false;
      }

      if (attrs?.max_amount) {
        return attrs.max_amount;
      }

      if (maxDonationAmount[selectedCode]) {
        return maxDonationAmount[selectedCode];
      }

      if (this.changeRate && maxDonationAmount[campaignCurrencyCode]) {
        return Math.ceil(
          (maxDonationAmount[campaignCurrencyCode] || 1) / this.changeRate,
        );
      }

      return false
    },

    lockGateway: () => JSON.parse(get('lock_gateway') || '0'),

    recurringOptDisabled(): boolean {
      const { disabled, force_recurring } = this.recurringConfig;

      if (force_recurring) {
        const forceRecurring = getNumber(get('force_recurring'));
        return Boolean(forceRecurring) && this.recurringConfig.disabled;
      }

      return disabled;
    },
  },

  watch: {
    originAmount: {
      handler(value: any) {
        const amount = this.selectedTeamsTotalAmount / 100;

        if (amount && amount > value) {
          this.checkExchange(amount);
        } else {
          this.checkExchange(value);
        }

        if (this.installmentAvailable) {
          this.recalculateInstalmentPeriod()
        }
      },
      immediate: true,
    },

    installmentTmOption(value: boolean): void {
      this.lockPresetInstallmentTmOption = true;
      this.$store.commit('setInstallmentTmOption', value);
    },

    installmentAvailable(newVal: boolean, oldVal: boolean): void {
      if (!newVal === oldVal) {
        this.installmentPeriod = 1;
      }

      if (newVal) {
        this.presetInstallments()
      }
    },

    showRecurringOption(newVal: boolean, oldVal: boolean): void {
      if (!newVal && oldVal) {
        this.recurringStatus = false;
      } else {
        this.getForceRecurring();
      }
    },

    showInstallmentOption(newVal: boolean, oldVal: boolean): void {
      if (!newVal && oldVal) {
        this.installmentPeriod = 1;
      }

      if (newVal) {
        this.presetInstallments()
      }
    },

    showFeeCheckbox(newVal: boolean, oldVal: boolean): void {
      if (!newVal === Boolean(oldVal)) {
        this.paymentFeeStatus = this.feeConfig?.checked || false;
      }
    },

    selectedTeamsTotalAmount(newAmount: number) {
      const amount = newAmount / 100;

      if (amount && amount > this.originAmount) {
        this.checkExchange(newAmount / 100);
      } else if (this.originAmount) {
        this.checkExchange(this.originAmount);
      } else {
        this.checkExchange(0);
      }
    },

    donationCurrency: {
      handler() {
        this.presetInstallmentTmOptionBasedMaxTmMonths();
        this.presetInstallmentTmOption();
        this.getMinAmountExchangeRate();
      },
      immediate: true,
    },

    paymentMethodWithId: {
      handler() {
        this.presetInstallmentTmOptionBasedMaxTmMonths();
        this.presetInstallmentTmOption();
      },
    },

    recurringDuration(range: number[]) {
      if (range.length && !range.includes(this.selectedRecurringPeriod)) {
        this.selectedRecurringPeriod = 0
      }
    },

    installmentAvailablePeriods(range: number[]) {
      if (!range.includes(this.installmentPeriod)) {
        this.installmentPeriod = 1
      }
    },

    feeAmount() {
      if (this.showFeeCheckbox && this.paymentFeeStatus) {
        this.$store.commit('setIncludePaymentFeeAmount', this.getFeeAmount() * 100)
      }
    },
  },

  mounted() {
    const amount = Number(get('amount')) || this.amountConfig?.default_amount;
    const currencyCode = get('currency');

    const currency = this.currencyList.find(
      (el: AvailableCurrencyList) => el.code === currencyCode.toLocaleUpperCase(),
    );

    this.presetInstallments();

    this.getForceRecurring();

    this.checkExchange(1, false);

    this.$nextTick(() => {
      if (currency) this.currencySelect(currency);
      if (amount) {
        this.$store.commit('setOriginAmount', amount);
        this.checkExchange(amount);
      }
    });

    this.setCustomStyle();
    this.setCustomScript();
  },

  methods: {
    getFeeAmount(): number {
      const { feeConfig, paymentMethod, donationAmount } = this;

      if (!feeConfig.fee_rate) {
        return 0;
      }

      const config = feeConfig.fee_rate[paymentMethod];

      if (!config) {
        return 0
      }

      const amount = Math.ceil(
        config.fixed + ((config.percentage * (donationAmount / 100)) / 100),
      )

      if (config.limit && config.limit < amount) {
        return config.limit
      }

      if (feeConfig.limit && feeConfig.limit < amount) {
        return feeConfig.limit
      }

      return amount;
    },

    recalculateInstalmentPeriod() {
      const installmentAmt = Number(this.getInstallmentMonthlyAmount(this.installmentPeriod))

      if (installmentAmt < 1) {
        this.installmentPeriod = (Number(this.amount) / 1) || 1
      }
    },

    presetInstallments() {
      const presetInstallments = Number(get('preset_installments'));

      if (presetInstallments && this.installmentAvailable) {
        this.$store.commit('setInstallmentPeriod', presetInstallments);
        this.$store.commit('setInstallmentStatus', presetInstallments > 1);
      }
    },

    presetInstallmentTmOptionBasedMaxTmMonths() {
      if (this.donationCurrency.code.toLowerCase() === 'ils'
        && this.installmentConfig.available_tm_option
        && this.installmentPeriod > this.installmentMaxTmOptionMonths
      ) {
        this.installmentTmOption = false;
      }
    },

    presetInstallmentTmOption() {
      if (this.lockPresetInstallmentTmOption) {
        this.lockPresetInstallmentTmOption = false
        return
      }

      const forGateways = installmentTmForGateways;
      // TODO combine two metas issue tm_option_by_default and gateway_for_tm
      // const { tm, non_tm } = this.config.gateway_for_tm_config;

      if (
        this.donationCurrency.code.toLowerCase() === 'ils'
        && this.installmentConfig.available_tm_option
        && this.installmentPeriod <= this.installmentMaxTmOptionMonths
        && this.installmentTmOption !== this.installmentConfig.tm_option_by_default
        // && tm === 0 && non_tm === 0
      ) {
        this.installmentTmOption = this.installmentConfig.tm_option_by_default;
      }

      if (
        this.donationCurrency.code.toLowerCase() === 'ils'
        && forGateways.includes(this.paymentMethod)
        && !this.installmentConfig.available_tm_option
      ) {
        this.installmentTmOption = true;
      } else if (!forGateways.includes(this.paymentMethod)) {
        this.installmentTmOption = false;
      }
    },

    getForceRecurring() {
      const { available, checked_by_default } = this.recurringConfig;
      const forceRecurring = getNumber(get('force_recurring'));

      if (available && checked_by_default) {
        this.$store.commit('setRecurringPeriod', this.recurringConfig.duration);
      }

      if (forceRecurring && available) {
        const cb = this.recurringUiMode === 'checkbox';
        if (cb) {
          this.$store.commit('setRecurringPeriod', this.recurringConfig.duration);
        } else if (Number(forceRecurring) > 1) {
          this.$store.commit('setRecurringPeriod', Number(forceRecurring));
        }
      }
    },

    checkExchange(data: string | number, setAmount = true) {
      const donationCurrency = this.donationCurrency.code.toLowerCase();
      const campaignCurrency = this.campaignCurrency.code.toLowerCase();
      const currentAmount = Number(data) || 0;

      if (currentAmount !== 0 && donationCurrency !== campaignCurrency) {
        const params = `?amount=${currentAmount}&from=${donationCurrency}&to=${campaignCurrency}`;
        axios
          .get<IXchangeResponse>(urls.getExchange + params)
          .then(response => {
            const { data } = response;
            const amountIn = data.data.attributes.amount_in;
            const amountOut = data.data.attributes.amount_out;

            if (setAmount) {
              this.$store.commit('setTotalAmount', amountOut);
            }

            const rate = amountIn === 1 ? amountOut : amountOut / amountIn;

            this.$store.commit('setChangeRate', rate);
          })
          .catch(() => {
            this.$store.dispatch('addError', {
              title: 'Exchange service error!',
              text: 'Please try later',
            });
          });
      } else {
        if (setAmount) {
          this.$store.commit('setTotalAmount', currentAmount);
        }
        this.$store.commit('setChangeRate', 0);
      }

      if (setAmount) {
        this.$store.commit('setDonationAmount', currentAmount);
      }
    },

    currencySelect(currency: any) {
      this.$store.commit('setDonationCurrency', { ...currency });
      this.$store.commit('setGiftAid', false);

      if (
        this.recurringConfig.currencies?.length
        && this.recurringConfig.currencies.indexOf(
          this.donationCurrency.code.toLowerCase(),
        ) === -1
      ) {
        this.$store.commit('setRecurringPeriod', 0);
      }
      this.checkExchange(
        this.donationAmount.toString() === ''
          ? ''
          : this.donationAmount / 100,
      );
      (this.$refs.amount as HTMLElement)?.focus();
    },

    setCustomStyle() {
      const head = document.head || document.getElementsByTagName('head')[0];

      if (this.customStyle) {
        let style = document.querySelector(
          'style[data-custom-style="true"]',
        ) as HTMLStyleElement;
        if (!style) {
          style = document.createElement('style') as HTMLStyleElement;
        }
        style.dataset.customStyle = 'true';
        style.textContent = this.customStyle;
        head.appendChild(style);
      }

      if (this.customStyleList?.length) {
        for (let i = 0; i < this.customStyleList.length; i += 1) {
          const styleData = this.customStyleList[i];

          if (styleData.value) {
            if (styleData.preview && get('preview') !== '1') {
              // eslint-disable-next-line no-continue
              continue
            }

            let style = document.querySelector(
              `style[data-custom-style-list="true"][data-title="${styleData.title}"][data-description="${styleData.description}"]`,
            ) as HTMLStyleElement;
            if (!style) {
              style = document.createElement('style') as HTMLStyleElement;
            }

            style.dataset.customStyleList = 'true';
            style.dataset.title = styleData.title;
            style.dataset.description = styleData.description;
            style.textContent = styleData.css;

            head.appendChild(style);
          }
        }
      }
    },

    getRecurringOptionText(months: number): string {
      const { period } = this.recurringConfig;

      if (period === 'week') {
        return this.$t(
          'donation.recurring_during_week',
          'Make my donation recurring for {duration} weeks {amount}',
          {
            duration: months,
            amount: `${this.donationCurrency.sign}${this.getRecurringMonthlyAmount(months)}`,
            currency_sign: this.donationCurrency.sign,
            total: (this.donationAmount / 100).toLocaleString(),
          },
          months,
        ) as string;
      }

      return this.$t(
        'donation.recurring_during',
        {
          months,
          monthly_amount: `${this.donationCurrency.sign}${this.getRecurringMonthlyAmount(months)}`,
          currency_sign: this.donationCurrency.sign,
          total: (this.donationAmount / 100).toLocaleString(),
        },
        months,
      ) as string;
    },

    getRecurringMonthlyAmount(month: number): string {
      return ((this.donationAmount / 100) * month * this.multiplierOnDay).toFixed(2);
    },

    setCustomScript(): void {
      if (this.customScript) {
        const scriptTryCatch = (script: string) => `try {${script}} catch(e) {console.warn('custom-script error: '+e)}`;
        const script = document.createElement('script');

        script.type = 'text/javascript';
        script.setAttribute('custome-script', 'true');
        script.appendChild(document.createTextNode(scriptTryCatch(this.customScript)));
        document.body.appendChild(script);
      }
    },

    getInstallmentMonthlyAmount(period: number): string {
      return (this.donationAmount / 100 / period).toFixed(1);
    },

    getAmoutFielError(): string {
      const selectedCode = this.donationCurrency.code.toUpperCase();
      const campaignCurrencyCode = this.campaignCurrency.code.toUpperCase();
      const minDonationAmount = this.config.minimum_donation_amount;

      const error = (this as any).vErrors.items.find((i: any) => i.field === 'amount');

      if (error.rule === 'min_value') {
        const tvars = {
          minInSelectedCurrecy: this.minAmount,
          selectedCurrencyCode: selectedCode,
          minInCampaignCurrecy: minDonationAmount[campaignCurrencyCode],
          campaignCurrencyCode,
        }

        if (this.installmentPeriod > 1) {
          return this.$t(
            'donation.installment_minimum_amount_is',
            'The amount field must be {minInSelectedCurrecy} {selectedCurrencyCode} or higher',
            tvars,
          ) as string
        }

        return this.$t('donation.minimum_amount_is', 'The amount field must be {minInSelectedCurrecy} {selectedCurrencyCode} or higher', tvars) as string
      }
      if (error.rule === 'max_value') {
        const tvars = {
          maxInSelectedCurrecy: this.maxAmount,
          selectedCurrencyCode: selectedCode,
          campaignCurrencyCode,
        };

        if (this.installmentPeriod > 1) {
          return this.$t(
            'donation.installment_maximum_amount_is',
            'The amount field must be {maxInSelectedCurrecy} {selectedCurrencyCode} or less',
            tvars,
          ) as string
        }

        return this.$t(
          'donation.maximum_amount_is',
          'The amount field must be {maxInSelectedCurrecy} {selectedCurrencyCode} or less',
          tvars,
        ) as string
      }
      if (error.rule === 'required') {
        if (this.installmentPeriod > 1) {
          return this.$t('donation.installment_amount_required') as string;
        }

        return this.$t('donation.amount_required') as string;
      }

      return error.msg;
    },

    async getMinAmountExchangeRate() {
      const minCurr = this.minFormAmountCurrencyCode.toLowerCase();
      const donCurr = this.donationCurrency.code.toLowerCase();

      if (donCurr !== minCurr && this.minFormAmount) {
        const params = {
          amount: 1,
          from: minCurr,
          to: donCurr,
        };
        const { data: { data } } = await axios.get<IXchangeResponse>(urls.getExchange, { params });
        const { attributes: attr } = data;

        this.minAmountChangeRate[donCurr.toUpperCase()] = attr.amount_out
        this.minAmountChangeRateLoading = true
      }
    },
  },
});
export default class DonationAmount extends VueComponent { }
