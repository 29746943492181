import { getGAClientID } from './analytics';

function injectGTMNoScript(id: string) {
  return new Promise(resolve => {
    const ns = document.createElement('noscript');
    const f = document.createElement('iframe');

    f.addEventListener('load', () => {
      resolve(true)
    });

    f.src = `//www.googletagmanager.com/ns.html?id=${id}`
    f.height = '0';
    f.width = '0';
    f.style.display = 'none';
    f.style.visibility = 'hidden';

    ns.append(f)

    document.body.insertAdjacentElement('afterbegin', ns);
  })
}

function injectGTMScript(dataLayerName: string, tagFunctionName: string, id: string) {
  return new Promise(resolve => {
    const script = document.createElement('script');

    script.addEventListener('load', () => {
      window[dataLayerName] = window[dataLayerName] || [];
      window[dataLayerName].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
      window[tagFunctionName] = (...args: any[]) => {
        window[dataLayerName]?.push(args);
      }

      window[tagFunctionName]('config', id);

      resolve(true)
    });

    script.async = true;
    script.src = `//www.googletagmanager.com/gtm.js?id=${id}&l=${dataLayerName}`;
    document.head.appendChild(script);
  })
}

export async function injectGoogleTagManager(dataLayerName: string, tagFunctionName: string, id: string) {
  await injectGTMScript(dataLayerName, tagFunctionName, id)
  await injectGTMNoScript(id);
}

export async function injectAnalaytics(params: {
  tracking_google_analytics_id: string | null,
  tracking_org_google_analytics_id: string | null,
  tracking_org_gtm_container: string | null,
}) {
  if (params.tracking_org_gtm_container !== null) {
    await injectGoogleTagManager(
      'orgDataLayer',
      'orgGtag',
      params.tracking_org_gtm_container,
    );
  }

  // global charidy analytics GA4
  await injectGoogleTagManager(
    'globalDataLayer',
    'globalGtag',
    'G-8HS2F3FCB8',
  );

  if (params.tracking_google_analytics_id !== null) {
    const fields = getGAClientID();
    ga('create', params.tracking_google_analytics_id, 'auto', { allowLinker: true, cookieFlags: 'max-age=7200;secure;samesite=none', ...fields });
    ga('send', 'pageview');
  }

  if (params.tracking_org_google_analytics_id !== null) {
    const fields = getGAClientID();
    ga('create', params.tracking_org_google_analytics_id, 'auto', 'org', { allowLinker: true, cookieFlags: 'max-age=7200;secure;samesite=none', ...fields });
    ga('org.send', 'pageview');
  }
}

export function gtag(...args: any[]) {
  window?.globalGtag?.(...args);
  window?.orgGtag?.(...args);
}

export default { injectGoogleTagManager }
